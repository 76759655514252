import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import {drawnCircle} from '../../../assets/images';
import {ResponsiveImage, ResponsiveText} from '../../../components';
import {appColors} from '../../../constants';
import styled from '../../../lib/styled';
import {getResponsiveSize} from '../../../lib/utilityFunctions';

interface CircledTextProps {
  text: string;
  style?: StyleProp<ViewStyle>;
}

export const CircledText: React.FC<CircledTextProps> = ({text, style}) => (
  <Container {...{style}}>
    <DrawnCircle source={drawnCircle} />
    <InnerText size={24}>{text}</InnerText>
  </Container>
);

const Container = styled(View)({
  width: getResponsiveSize(166),
  height: getResponsiveSize(166),
  justifyContent: 'center',
  alignItems: 'center',
});

const DrawnCircle = styled(ResponsiveImage)({
  width: '100%',
});

const InnerText = styled(ResponsiveText)({
  position: 'absolute',
  color: appColors.black,
  fontFamily: 'Roboto-Bold',
  letterSpacing: getResponsiveSize(7.2),
});
