import {useIsFocused} from '@react-navigation/native';
import {ProductName} from 'pikaparam';
import {FavoriteDeviceDto} from 'puffco-api-axios-client';
import React from 'react';
import {Helmet} from 'react-helmet';
import {View} from 'react-native';

import {net} from '../../../assets/images';
import {
  LinearGradientBackground,
  ResponsiveImage,
  ResponsiveText,
  RowContainer,
  SwitchableBackground,
} from '../../../components';
import {Constants, appColors} from '../../../constants';
import {Screens} from '../../../constants';
import {formatNumberWithCommas} from '../../../lib/formatNumber';
import styled from '../../../lib/styled';
import {getResponsiveSize} from '../../../lib/utilityFunctions';
import {RehashStoryScreenProps} from '../../../navigation/navigators/RehashStoryNavigator';
import {BoldText, MediumText} from '../components';
import {RehashScreen} from '../components/RehashScreen';
import {getDeviceTheme} from '../themes';

export interface Props extends FavoriteDeviceDto {}

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashFavoriteDevice> {}

export const FavoriteDevice: React.FC<ScreenProps> = ({navigation, route}) => {
  const {
    title,
    subtitle,
    deviceName: name,
    deviceModel: model,
    dabCount,
  } = route.params;

  const theme = getDeviceTheme(model as ProductName);

  const isFocused = useIsFocused();

  React.useEffect(() => {
    navigation.setOptions({
      headerTintColor: theme.controlsColor,
    });
  }, [theme.controlsColor]);

  return (
    <RehashScreen as={Container} background={{gradient: theme.colors}}>
      {Constants.IS_IOS_WEB && isFocused && (
        <Helmet>
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content={
              theme.controlsColor === appColors.black
                ? 'black-translucent'
                : 'default'
            }
          />
        </Helmet>
      )}

      <UpperContainer>
        <ModelContainer>
          <ModelName
            numberOfLines={1}
            size={18}
            style={{color: theme.modelNameColor}}>
            {`${model} Peak Pro`}
          </ModelName>
        </ModelContainer>

        <IllustrationsContainer>
          <BackgroundNet source={net} tintColor={theme.illustrationColor} />

          <PeakContainer>
            <GradientBackground colors={theme.gradientColors} />

            <PeakRender source={theme.image} />
          </PeakContainer>
        </IllustrationsContainer>
      </UpperContainer>

      <DataContainer>
        <NameContainer>
          <MediumText size={24} style={{color: theme.titleColor}}>
            {title}
          </MediumText>

          <BoldText
            numberOfLines={2}
            size={48}
            style={{color: theme.nameColor}}>
            {name}
          </BoldText>
        </NameContainer>

        <ResponsiveText style={{color: theme.nameColor}}>
          <BoldText style={{color: theme.nameColor}}>
            {formatNumberWithCommas(dabCount)}
          </BoldText>
          &nbsp;
          {subtitle}
        </ResponsiveText>
      </DataContainer>
    </RehashScreen>
  );
};

const Container = styled(SwitchableBackground)({
  flex: 1,
  gap: getResponsiveSize(32),
});

const UpperContainer = styled(RowContainer)({
  flex: 1,
  paddingTop: '5%',
});

const ModelContainer = styled(View)({
  transform: [{rotate: '-90deg'}],
  alignItems: 'center',
});

const ModelName = styled(ResponsiveText)({
  color: appColors.white,
  textAlign: 'center',
});

const IllustrationsContainer = styled(View)({
  flex: 1,
  marginTop: getResponsiveSize(24),
  justifyContent: 'center',
  position: 'relative',
  height: '100%',
});

const PeakContainer = styled(View)({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-end',
  position: 'absolute',
  width: '90%',
});

const BackgroundNet = styled(ResponsiveImage)({
  height: '100%',
});

const GradientBackground = styled(LinearGradientBackground)({
  aspectRatio: 1.18,
  minWidth: 220,
  width: '100%',
  height: 'auto',
});

const PeakRender = styled(ResponsiveImage)({
  position: 'absolute',
  height: '160%',
  aspectRatio: 0.34,
});

const DataContainer = styled(View)({
  paddingLeft: getResponsiveSize(32),
  gap: getResponsiveSize(40),
  marginTop: getResponsiveSize(24),
  marginBottom: getResponsiveSize(42),
});

const NameContainer = styled(View)({
  gap: getResponsiveSize(10),
});
