import React from 'react';
import {
  Dimensions,
  Image,
  Linking,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import {appColors} from '../../../../constants';
import {PressableOpacity} from '../../../../shims/PressableOpacity';

interface Props {
  thumbnail: string;
  title?: string;
  link: string;
  useFullWidth?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

export const VideoItem = ({
  thumbnail,
  title,
  link,
  useFullWidth = false,
  onPress,
}: Props) => {
  const width = Dimensions.get('window').width * (useFullWidth ? 1 : 0.8);

  return (
    <View
      style={{
        width,
        paddingRight: useFullWidth ? 40 : 0,
      }}>
      <PressableOpacity
        onPress={() => {
          Linking.openURL(link);

          onPress?.();
        }}>
        <Image
          source={{uri: thumbnail}}
          resizeMode="cover"
          style={styles.thumbnail}
        />
      </PressableOpacity>

      {!!title && <Text style={styles.title}>{title}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  thumbnail: {
    borderRadius: 12,
    borderColor: appColors.darkGray,
    borderWidth: 1,
    aspectRatio: 1.7,
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: appColors.white,
    marginTop: 10,
  },
});
