import {UserDabsDto} from 'puffco-api-axios-client';
import React from 'react';
import {Image, View} from 'react-native';

import {userDabs} from '../../../assets/images';
import {
  ResponsiveText,
  RowContainer,
  SwitchableBackground,
} from '../../../components';
import {appColors} from '../../../constants';
import {Screens} from '../../../constants';
import {formatNumberWithCommas} from '../../../lib/formatNumber';
import styled from '../../../lib/styled';
import {addOpacityToColorHex} from '../../../lib/utilityFunctions';
import {RehashStoryScreenProps} from '../../../navigation/navigators/RehashStoryNavigator';
import {MediumText} from '../components';
import {RehashScreen} from '../components/RehashScreen';

export interface Props extends UserDabsDto {}

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashUserDabs> {}

const theme = {
  background: {color: '#CFC8BB'},
};

export const UserDabs: React.FC<ScreenProps> = ({route}) => {
  const {dabCount, title} = route.params;

  return (
    <RehashScreen as={Container} background={theme.background}>
      <UserDabsImage source={userDabs} />

      <ContentContainer>
        <Title size={24}>{title}</Title>

        <CountContainer>
          <DabCountText size={88}>
            {formatNumberWithCommas(dabCount)}
          </DabCountText>

          <DabCountText size={56}>+</DabCountText>
        </CountContainer>
      </ContentContainer>
    </RehashScreen>
  );
};

const UserDabsImage = styled(Image)({
  flex: 1,
  resizeMode: 'contain',
  alignSelf: 'center',
  marginHorizontal: 22,
  marginBottom: 30,
});

const Container = styled(SwitchableBackground)({
  flex: 1,
  gap: 30,
  paddingBottom: '10%',
});

const ContentContainer = styled(View)({
  alignItems: 'center',
});

const Title = styled(MediumText)({
  color: addOpacityToColorHex(appColors.black, 0.5),
});

const CountContainer = styled(RowContainer)({
  justifyContent: 'center',
  gap: 10,
});

const DabCountText = styled(ResponsiveText)({
  color: appColors.vividOrange,
  fontFamily: 'Roboto-Bold',
});
