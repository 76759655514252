import {BaseRehashDto} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, Image, View} from 'react-native';

import {communityIntro} from '../../../assets/images';
import {ResponsiveText, SwitchableBackground} from '../../../components';
import {appColors} from '../../../constants';
import {Screens} from '../../../constants';
import styled from '../../../lib/styled';
import {RehashStoryScreenProps} from '../../../navigation/navigators/RehashStoryNavigator';
import {BoldText} from '../components';
import {RehashScreen} from '../components/RehashScreen';

export interface Props extends BaseRehashDto {}

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashCommunityIntro> {}

const theme = {
  background: {color: appColors.black},
};
const {height, width} = Dimensions.get('window');

const imageWidth = width * 1.5;
const iamgeHeight = height * 0.72;
const offset = -imageWidth / 3.2;

export const CommunityIntro: React.FC<ScreenProps> = ({route}) => {
  const {title, subtitle} = route.params;

  return (
    <RehashScreen as={Container} background={theme.background}>
      <SubtitleContainer>
        <Subtitle size={18}>{subtitle}</Subtitle>
      </SubtitleContainer>

      <Image
        source={communityIntro}
        style={{
          alignSelf: 'flex-start',
          position: 'absolute',
          left: offset,
          width: imageWidth,
          height: iamgeHeight,
          resizeMode: 'contain',
        }}
      />

      <Title size={112}>{title}</Title>
    </RehashScreen>
  );
};

const Container = styled(SwitchableBackground)({
  flex: 1,
});

const Title = styled(BoldText)({
  position: 'absolute',
  transform: [{rotate: '-45deg'}],
  top: '50%',
  alignSelf: 'center',
  width: '140%',
});

const SubtitleContainer = styled(View)({
  position: 'absolute',
  top: iamgeHeight * 0.03,
  left: offset + (imageWidth * 0.95) / 1.5,
  width: '50%',
});

const Subtitle = styled(ResponsiveText)({
  color: appColors.white,
  lineHeight: 25,
});
