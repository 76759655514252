import {createAsyncThunk} from '@reduxjs/toolkit';

import {Store} from '../../types';
import {connectedPeakSelector, updateDevice} from '../bleSlice';

type WriteDeviceNameArgs = {
  name: string;
};

export const bleWriteDeviceName = createAsyncThunk<void, WriteDeviceNameArgs>(
  'ble/writeDeviceName',
  async ({name}, {dispatch, getState}) => {
    const state = getState() as Store;
    const peak = connectedPeakSelector(state);

    if (!peak) return;

    await peak.writeDeviceName(name);

    dispatch(updateDevice({id: peak.peripheralId, name}));
  },
);
