import React from 'react';
import {StyleSheet, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import {Strings} from '../../../../constants';
import {isResolvedEntryLink, resolveAssetUrl} from '../../../../lib/api';
import {TypeVideo} from '../../../../lib/api/content-access/types';
import {analytics} from '../../../../src/services/analytics';
import {Section} from '../Section';
import {VideoItem} from './VideoItem';

interface Props {
  videos: TypeVideo[];
}

export const DeviceVideoCarousel = ({videos}: Props) => {
  return (
    <Section title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.VIDEOS}>
      <FlatList
        data={videos}
        renderItem={({item}: {item: TypeVideo}) => {
          if (!isResolvedEntryLink(item)) return null;

          return (
            <VideoItem
              thumbnail={resolveAssetUrl(item.fields.thumbnailImage)}
              title={item.fields.title}
              link={item.fields.link}
              onPress={() => {
                analytics.trackEvent('click', {
                  link_url: item.fields.link,
                });
              }}
              useFullWidth={videos.length === 1 ? true : false}
            />
          );
        }}
        keyExtractor={item => (item as TypeVideo).sys.id}
        extraData={videos}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        contentContainerStyle={styles.container}
      />
    </Section>
  );
};

const styles = StyleSheet.create({
  separator: {width: 20},
  container: {paddingHorizontal: 20},
});
