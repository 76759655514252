import React from 'react';
import {StyleSheet} from 'react-native';

import {ResponsiveText, StyledIcon} from '../../../components';
import {Strings, appColors} from '../../../constants';
import {getResponsiveSize} from '../../../lib/utilityFunctions';
import {
  PressableOpacity,
  Props as PressableOpacityProps,
} from '../../../shims/PressableOpacity';

interface Props extends PressableOpacityProps {}

export const DownloadButton = ({style, ...rest}: Props) => {
  return (
    <PressableOpacity style={[styles.container, style]} {...rest}>
      <StyledIcon
        name="downloadCircle"
        size={getResponsiveSize(56)}
        containerSize={getResponsiveSize(56)}
        style={styles.icon}
        iconStyle={{tintColor: appColors.black}}
      />

      <ResponsiveText size={18}>{Strings.DOWNLOAD}</ResponsiveText>
    </PressableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  icon: {
    marginBottom: 13,
  },
});
