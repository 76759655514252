import {BaseRehashDto} from 'puffco-api-axios-client';
import React from 'react';
import {Image, StyleSheet} from 'react-native';

import {empty} from '../../../assets/images';
import {ResponsiveText, SwitchableBackground} from '../../../components';
import {appColors} from '../../../constants';
import {Screens} from '../../../constants';
import {formatText} from '../../../lib/utilityFunctions';
import {RehashStoryScreenProps} from '../../../navigation/navigators/RehashStoryNavigator';
import {BoldText, Underlined} from '../components';
import {RehashScreen} from '../components/RehashScreen';

export interface Props extends BaseRehashDto {}

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashEmpty> {}

const theme = {
  background: {color: appColors.black},
};

export const Empty: React.FC<ScreenProps> = ({route}) => {
  const {title, subtitle} = route.params;

  return (
    <RehashScreen
      as={SwitchableBackground}
      style={styles.container}
      background={theme.background}>
      <BoldText size={28} style={styles.title}>
        {formatText(title, text => (
          <Underlined
            size={28}
            lineHeight={46}
            underlineHeight={7}
            {...{text}}
          />
        ))}
      </BoldText>

      <ResponsiveText size={20} style={styles.subtitle}>
        {subtitle}
      </ResponsiveText>

      <Image style={styles.image} source={empty} />
    </RehashScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: '15%',
  },
  title: {
    marginTop: '15%',
    marginBottom: 26,
    paddingHorizontal: 31,
    lineHeight: 46,
  },
  subtitle: {
    paddingHorizontal: 31,
    color: appColors.white,
    opacity: 0.5,
    marginBottom: 40,
    lineHeight: 36,
  },
  image: {
    flex: 1,
    width: '100%',
    resizeMode: 'contain',
  },
});
