import React from 'react';
import {View, ViewStyle} from 'react-native';

import {CircledText} from '.';
import {rehashText} from '../../../assets/images';
import {ResponsiveImage} from '../../../components';
import styled from '../../../lib/styled';
import {RippedIllustration} from './RippedIllustration';

interface IntroBannerProps {
  text: string;
  style?: ViewStyle;
}

export const IntroBanner: React.FC<IntroBannerProps> = ({text, style}) => (
  <View {...{style}}>
    <Illustration width={'100%'} />

    <RehashIllustration source={rehashText} />

    <YearIllustration {...{text}} />
  </View>
);

const Illustration = styled(RippedIllustration)({
  position: 'absolute',
  maxWidth: '95%',
  zIndex: 0,
});

const RehashIllustration = styled(ResponsiveImage)({
  position: 'absolute',
  maxHeight: '70%',
  left: '6%',
  top: '-18%',
  zIndex: 1,
});

const YearIllustration = styled(CircledText)({
  position: 'absolute',
  right: '5%',
  top: '25%',
  zIndex: 0,
});
