import React from 'react';
import {Dimensions, View} from 'react-native';
import Svg, {Path} from 'react-native-svg';

import {appColors} from '../../../constants';
import styled from '../../../lib/styled';
import {addOpacityToColorHex} from '../../../lib/utilityFunctions';

const {width, height} = Dimensions.get('window');

const ICON_SIZE = 23;
const HORIZONTAL_SPACING = 17;
const VERTICAL_SPACING = 22;

const numColumns = Math.floor(width / (ICON_SIZE + HORIZONTAL_SPACING));
const numRows = Math.floor(height / (ICON_SIZE + VERTICAL_SPACING));

const gridData = Array.from({length: numColumns * numRows});

// Favorite moodlight background pluses
export const BackgroundGrid = () => (
  <Container>
    {gridData.map((_, index) => (
      <IconContainer key={index}>
        <PlusIcon />
      </IconContainer>
    ))}
  </Container>
);

// Using styled icons is laggy.
export const PlusIcon = ({
  width = 23,
  height = 23,
  color = addOpacityToColorHex(appColors.white, 0.1),
}) => (
  <Svg width={width} height={height} viewBox="0 0 23 23" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 0.5C12.5 0.223858 12.2761 0 12 0C11.7239 0 11.5 0.223858 11.5 0.5V11H0.5C0.223858 11 0 11.2239 0 11.5C0 11.7761 0.223858 12 0.5 12H11.5V22.5C11.5 22.7761 11.7239 23 12 23C12.2761 23 12.5 22.7761 12.5 22.5V12H22.5C22.7761 12 23 11.7761 23 11.5C23 11.2239 22.7761 11 22.5 11H12.5V0.5Z"
      fill={color}
    />
  </Svg>
);

const Container = styled(View)({
  position: 'absolute',
  top: '-20%',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  alignContent: 'center',
  zIndex: -10,
  pointerEvents: 'none',
});

const IconContainer = styled(View)({
  marginHorizontal: HORIZONTAL_SPACING / 2,
  marginVertical: VERTICAL_SPACING / 2,
});
