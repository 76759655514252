import {
  DrawerScreenProps,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import {
  CompositeScreenProps,
  NavigatorScreenParams,
  useNavigation,
} from '@react-navigation/native';
import {
  StackScreenProps,
  TransitionPresets,
  createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';
import {Platform} from 'react-native';

import {ManualPdfProps, StyledIcon} from '../../components';
import {
  Messages,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {HeatProfileType, Profile} from '../../lib/types';
import {useTheme} from '../../lib/useTheme';
import {DrawerContent} from '../../navigation/DrawerContent/DrawerContent';
import {
  ChangeUsernameScreen,
  EmailVerifyScreen,
  HeatProfileSelectScreen,
  SaveToDeviceScreen,
  StaticScreenInterstitial,
} from '../../screens';
import {
  AccountCreatedScreen,
  Props as AccountCreatedScreenProps,
} from '../../screens/login/AccountCreatedScreen';
import {
  DabbingScreen,
  Props as DabbingScreenProps,
} from '../../screens/main/DabbingScreen';
import {
  SharedMoodScreen,
  Props as SharedMoodScreenProps,
} from '../../screens/main/SharedMoodScreen';
import {CleaningManualScreen} from '../../screens/settings/CleaningManualScreen';
import {CleaningReminderScreen} from '../../screens/settings/CleaningReminderScreen';
import {FactoryResetScreen} from '../../screens/settings/FactoryResetScreen';
import {FirmwareInfoScreen} from '../../screens/settings/FirmwareInfoScreen';
import {
  FirmwareUpdatingScreen,
  Props as FirmwareUpdatingScreenProps,
} from '../../screens/settings/FirmwareUpdatingScreen/FirmwareUpdatingScreen';
import {PreferencesScreen} from '../../screens/settings/PreferencesScreen';
import {ReminderFrequencyScreen} from '../../screens/settings/ReminderFrequencyScreen';
import {SnoozeFrequencyScreen} from '../../screens/settings/SnoozeFrequencyScreen';
import {TemperatureSettingsScreen} from '../../screens/settings/TemperatureSettingsScreen';
import {ContactUsScreen} from '../../screens/support/ContactUsScreen';
import {HowToVideosScreen} from '../../screens/support/HowToVideosScreen';
import {SupportScreen} from '../../screens/support/SupportScreen';
import {AdvancedMetricsScreen} from '../../src/screens/usage/AdvancedMetricsScreen';
import {CustomModalSlideFromBottomIOS} from '../CustomTransitionPreset';
import {
  AccountStackNavigator,
  AccountStackParamList,
} from './AccountStackNavigator';
import {CommunityStack, CommunityStackParamList} from './CommunityStack';
import {
  ControlCenterStackNavigator,
  ControlCenterStackParamList,
} from './ControlCenterStackNavigator';
import {
  DeviceEducationStack,
  DeviceEducationStackParamList,
} from './DeviceEducationStack';
import {DevicesListStack, DevicesListStackParamList} from './DevicesListStack';
import {GuideStack, GuideStackParamList} from './GuideStack';
import {HomeTabNavigator, HomeTabNavigatorParamList} from './HomeTabNavigator';
import {
  ReferralProgramStack,
  ReferralStackParamList,
} from './ReferralProgramStack';
import {
  RehashStoryNavigator,
  RehashStoryParamList,
} from './RehashStoryNavigator';
import {
  MainNavigatorScreenProps,
  RootStackParamList,
  RootStackScreenProps,
} from './RootStackNavigator';
import {
  SharedHeatProfileStackNavigator,
  SharedHeatProfileStackParamList,
} from './SharedHeatProfileStackNavigator';
import {WarrantyStack, WarrantyStackParamList} from './WarrantyStack';
import {
  defaultNavigationOptions,
  defaultNoHeaderScreenNavigation,
  withBackButton,
  withHamburgerMenu,
} from './common';
import {WithOptionalRedirect} from './params';

export type HomeEmulatedDrawerStackParamList = {
  [Navigators.HomeTabNavigator]: NavigatorScreenParams<HomeTabNavigatorParamList>;
  [Navigators.RehashStory]: NavigatorScreenParams<RehashStoryParamList>;
  [Navigators.Account]: NavigatorScreenParams<AccountStackParamList>;
  [Navigators.SharedHeatProfile]: NavigatorScreenParams<SharedHeatProfileStackParamList>;
  [Navigators.ControlCenter]: NavigatorScreenParams<ControlCenterStackParamList>;
  [Navigators.DevicesList]: NavigatorScreenParams<DevicesListStackParamList>;
  [Navigators.DeviceEducation]: NavigatorScreenParams<DeviceEducationStackParamList>;
  [Navigators.ReferralProgram]:
    | NavigatorScreenParams<ReferralStackParamList>
    | undefined;
  [Navigators.Guide]: NavigatorScreenParams<GuideStackParamList>;
  [Navigators.Community]: NavigatorScreenParams<CommunityStackParamList>;
  [Navigators.Warranty]: NavigatorScreenParams<WarrantyStackParamList>;
  [Screens.ChangeUsername]: WithOptionalRedirect | undefined;
  [Screens.EmailVerify]: undefined;
  [Screens.SharedMood]: SharedMoodScreenProps;
  [Screens.StaticScreenInterstitial]: {
    component: React.ComponentType<{onNavigatingAway: () => void}>;
    onNavigatingAway: () => void;
  };
  [Screens.HeatProfileSelect]:
    | {
        profile?: Profile;
        type?: HeatProfileType;
      }
    | undefined;
  [Screens.Dabbing]?: DabbingScreenProps;
  [Screens.SaveToDevice]: {
    archiveIndex: number;
  };
  [Screens.Preferences]: undefined;
  [Screens.AdvancedMetrics]: undefined;
  [Screens.FirmwareInfo]: undefined;
  [Screens.FirmwareUpdating]: FirmwareUpdatingScreenProps;
  [Screens.CleaningReminder]: undefined;
  [Screens.FactoryReset]: undefined;
  [Screens.TemperatureSettings]: undefined;
  [Screens.ReminderFrequency]: undefined;
  [Screens.SnoozeFrequency]: undefined;
  [Screens.CleaningManual]: {
    source: ManualPdfProps['source'];
  };
  [Screens.Support]: undefined;
  [Screens.HowToVideos]: undefined;
  [Screens.ContactUs]: undefined;
  [Screens.AccountCreated]: AccountCreatedScreenProps | undefined;
};

export type HomeEmulatedDrawerStackScreenProps<
  T extends keyof HomeEmulatedDrawerStackParamList,
> = CompositeScreenProps<
  StackScreenProps<HomeEmulatedDrawerStackParamList, T>,
  HomeDrawerScreenProps<typeof Navigators.HomeEmulatedDrawer>
>;

export type HomeTabNavigatorScreenProps<
  T extends keyof HomeTabNavigatorParamList,
> = CompositeScreenProps<
  StackScreenProps<HomeTabNavigatorParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

export type RehashStoryNavigatorScreenProps<
  T extends keyof RehashStoryParamList,
> = CompositeScreenProps<
  StackScreenProps<RehashStoryParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

export type AccountNavigatorScreenProps<T extends keyof AccountStackParamList> =
  CompositeScreenProps<
    StackScreenProps<AccountStackParamList, T>,
    HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
  >;

export type SharedHeatProfileNavigatorScreenProps<
  T extends keyof SharedHeatProfileStackParamList,
> = CompositeScreenProps<
  StackScreenProps<SharedHeatProfileStackParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

export type ControlCenterNavigatorScreenProps<
  T extends keyof ControlCenterStackParamList,
> = CompositeScreenProps<
  StackScreenProps<ControlCenterStackParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

export type DevicesListNavigatorScreenProps<
  T extends keyof DevicesListStackParamList,
> = CompositeScreenProps<
  StackScreenProps<DevicesListStackParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

export type DeviceEducationNavigatorScreenProps<
  T extends keyof DeviceEducationStackParamList,
> = CompositeScreenProps<
  StackScreenProps<DeviceEducationStackParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

export type ReferralNavigatorScreenProps<
  T extends keyof ReferralStackParamList,
> = CompositeScreenProps<
  StackScreenProps<ReferralStackParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

export type CommunityNavigatorScreenProps<
  T extends keyof CommunityStackParamList,
> = CompositeScreenProps<
  StackScreenProps<CommunityStackParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

export type GuideNavigatorScreenProps<T extends keyof GuideStackParamList> =
  CompositeScreenProps<
    StackScreenProps<GuideStackParamList, T>,
    HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
  >;

export type WarrantyNavigatorScreenProps<
  T extends keyof WarrantyStackParamList,
> = CompositeScreenProps<
  StackScreenProps<WarrantyStackParamList, T>,
  HomeEmulatedDrawerStackScreenProps<keyof HomeEmulatedDrawerStackParamList>
>;

const HomeEmulatedDrawerStack =
  createStackNavigator<HomeEmulatedDrawerStackParamList>();

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const HomeEmulatedDrawerStackNavigator = () => {
  const theme = useTheme();
  const navigation = useNavigation<Navigation>();

  return (
    <HomeEmulatedDrawerStack.Navigator
      screenOptions={{
        ...defaultNavigationOptions,
        headerTitle: '',
        presentation: 'transparentModal',
        headerLeft: () => null,
      }}
      initialRouteName={Navigators.HomeTabNavigator}>
      <HomeEmulatedDrawerStack.Screen
        name={Navigators.HomeTabNavigator}
        component={HomeTabNavigator}
        options={{...withHamburgerMenu({themed: true})}}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.RehashStory}
        component={RehashStoryNavigator}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.Account}
        component={AccountStackNavigator}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.ControlCenter}
        component={ControlCenterStackNavigator}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.DevicesList}
        component={DevicesListStack}
        options={{...withHamburgerMenu()}}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.DeviceEducation}
        component={DeviceEducationStack}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.Guide}
        component={GuideStack}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.Warranty}
        component={WarrantyStack}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.SharedHeatProfile}
        component={SharedHeatProfileStackNavigator}
        options={{
          ...TransitionPresets.ModalSlideFromBottomIOS,
          gestureEnabled: false,
          cardOverlayEnabled: true,
          headerShown: false,
          cardStyle: {
            backgroundColor:
              Platform.OS !== 'android' ? '#000000A6' : '#000000E6',
          },
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.ChangeUsername}
        component={ChangeUsernameScreen}
        options={{
          headerLeft: () => null,
          headerRight: () => null,
          headerBackTitle: ' ',
          headerTitle: '',
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.EmailVerify}
        component={EmailVerifyScreen}
        options={{headerStatusBarHeight: 10, headerTitle: ''}}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.SharedMood}
        component={SharedMoodScreen}
        options={{
          headerTitle: Strings.SHARED_MOOD,
          headerTitleStyle: theme.navMenuTitleStyle,
          headerLeft: () => null,
          ...TransitionPresets.ModalSlideFromBottomIOS,
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.StaticScreenInterstitial}
        component={StaticScreenInterstitial}
        options={defaultNoHeaderScreenNavigation}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.HeatProfileSelect}
        component={HeatProfileSelectScreen}
        options={{headerShown: true}}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.Dabbing}
        component={DabbingScreen}
        options={{
          ...CustomModalSlideFromBottomIOS,
          ...defaultNavigationOptions,
          headerTitle: '',
          headerLeft: () => null,
          gestureEnabled: false,
          cardOverlayEnabled: true,
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.SaveToDevice}
        component={SaveToDeviceScreen}
        options={{
          headerTitle: 'SAVE TO DEVICE',
          headerTitleStyle: theme.navMenuTitleStyle,
          headerLeft: () => (
            <StyledIcon
              name="chevronLeft"
              onPress={() => navigation.goBack()}
              iconStyle={{tintColor: theme.navMenuIconColor}}
            />
          ),
          headerRight: () => null,
          gestureEnabled: false,
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.ReferralProgram}
        component={ReferralProgramStack}
        options={{...withHamburgerMenu()}}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Navigators.Community}
        component={CommunityStack}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.Preferences}
        component={PreferencesScreen}
        options={{
          headerTitle: 'SETTINGS',
          ...withHamburgerMenu(),
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.AdvancedMetrics}
        component={AdvancedMetricsScreen}
        options={{
          headerTitle: Strings.ADVANCED_METRICS.toUpperCase(),
          ...withHamburgerMenu(),
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.FirmwareInfo}
        component={FirmwareInfoScreen}
        options={{
          ...withBackButton(),
          headerTitle: 'FIRMWARE',
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.FirmwareUpdating}
        component={FirmwareUpdatingScreen}
        options={{
          headerLeft: () => null,
          headerTitle: 'FIRMWARE',
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.CleaningReminder}
        component={CleaningReminderScreen}
        options={{
          ...withBackButton(),
          headerTitle: Messages.CLEANING_REMINDER_NAVIGATION_TITLE,
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.FactoryReset}
        component={FactoryResetScreen}
        options={{
          ...withBackButton(),
          headerTitle: 'FACTORY RESET',
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.TemperatureSettings}
        component={TemperatureSettingsScreen}
        options={{
          ...withBackButton(),
          headerTitle: 'TEMPERATURE SETTINGS',
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.ReminderFrequency}
        component={ReminderFrequencyScreen}
        options={{
          ...withBackButton(),
          headerTitle: Messages.REMINDER_SECTION_HEADER_TITLE,
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.SnoozeFrequency}
        component={SnoozeFrequencyScreen}
        options={{
          ...withBackButton(),
          headerTitle: Messages.SNOOZE_FREQUENCY_SCREEN_TITLE,
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.CleaningManual}
        component={CleaningManualScreen}
        options={{
          ...withBackButton(),
          headerTitle: Strings.CLEANING_MANUAL,
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.Support}
        component={SupportScreen}
        options={{
          ...withBackButton(),
          headerTitle: 'SUPPORT',
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.HowToVideos}
        component={HowToVideosScreen}
        options={{
          ...withBackButton(),
          headerTitle: 'HOW TO VIDEOS',
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.ContactUs}
        component={ContactUsScreen}
        options={{
          headerTitle: 'CONTACT US',
          ...withBackButton(),
        }}
      />

      <HomeEmulatedDrawerStack.Screen
        name={Screens.AccountCreated}
        component={AccountCreatedScreen}
        options={defaultNoHeaderScreenNavigation}
      />
    </HomeEmulatedDrawerStack.Navigator>
  );
};

export type HomeDrawerParamList = {
  [Navigators.HomeEmulatedDrawer]: NavigatorScreenParams<HomeEmulatedDrawerStackParamList>;
};

type HomeDrawerScreenProps<T extends keyof HomeDrawerParamList> =
  CompositeScreenProps<
    DrawerScreenProps<HomeDrawerParamList, T>,
    MainNavigatorScreenProps<typeof Navigators.HomeDrawerNavigator>
  >;

export type HomeEmulatedDrawerNavigatorScreenProps<
  T extends keyof HomeEmulatedDrawerStackParamList,
> = CompositeScreenProps<
  DrawerScreenProps<HomeEmulatedDrawerStackParamList, T>,
  HomeDrawerScreenProps<keyof HomeDrawerParamList>
>;

const HomeDrawer = createDrawerNavigator<HomeDrawerParamList>();

export const HomeDrawerNavigator = () => {
  return (
    <HomeDrawer.Navigator
      initialRouteName={Navigators.HomeEmulatedDrawer}
      drawerContent={DrawerContent}
      screenOptions={{
        ...defaultNavigationOptions,
        overlayColor: appColors.drawerFade,
        drawerStyle: {
          width: '100%',
        },
        headerTitle: '',
        swipeEnabled: false,
        headerShown: false,
      }}>
      <HomeDrawer.Screen
        name={Navigators.HomeEmulatedDrawer}
        component={HomeEmulatedDrawerStackNavigator}
      />
    </HomeDrawer.Navigator>
  );
};
