import React from 'react';
import {ViewProps} from 'react-native';
import {interpolate} from 'react-native-reanimated';

import {HorizontalSlider} from '../../components/HorizontalSlider';
import {useSyncedSharedValue} from '../../components/Slider';
import {
  SliderContainer,
  SliderValue,
  SliderValues,
} from '../../components/SliderContainer';

interface Props extends Omit<ViewProps, 'children'> {
  title: React.ReactNode;
  value: number;
  min: number;
  max: number;
  parse?(value: number): number;
  children(value: number): React.ReactNode;
  onChange(value: number): void;
  onEnd(value: number): void;
}

const fromValue = (value: number, min: number, max: number) =>
  interpolate(value, [0, 1], [min, max]);
const toValue = (value: number, min: number, max: number) =>
  interpolate(value, [min, max], [0, 1]);

export const MoodlightSlider: React.FC<Props> = ({
  title,
  value: initialValue,
  min,
  max,
  parse = v => v,
  style,
  children,
  onChange,
  onEnd,
}) => {
  const [_, setDisplayValue] = React.useState(parse(initialValue));

  const value = useSyncedSharedValue(
    parse(initialValue),
    value => toValue(value, min, max),
    [initialValue, min, max],
  );

  return (
    <SliderContainer {...{style}}>
      <SliderValues>
        <SliderValue highlighted>{title}</SliderValue>
        <SliderValue highlighted>
          {children(parse(fromValue(value.value, min, max)))}
        </SliderValue>
      </SliderValues>

      <HorizontalSlider
        {...{value}}
        onChange={value => {
          value = parse(fromValue(value, min, max));
          setDisplayValue(value);
          onChange(value);
        }}
        onEnd={value => onEnd(parse(fromValue(value, min, max)))}
      />
    </SliderContainer>
  );
};
