import {contextApi} from '../../lib/api/apis';
import {useImmutableRemoteData} from '../../lib/hooks/useRemoteData';
import {config} from './features';

interface Options {
  userId?: number;
}

export const useLoadContext = ({userId}: Options) => {
  return useImmutableRemoteData({key: 'useGlobal', userId}, () =>
    contextApi
      .getContext()
      .then(r => ({...r.data, features: {...config, ...r.data.features}})),
  );
};
