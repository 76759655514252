import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

export type LinearGradientProps = {
  colors: (string | number)[];
  start?: {x: number; y: number};
  end?: {x: number; y: number};
  locations?: number[];
  useAngle?: boolean;
  angleCenter?: {x: number; y: number};
  angle?: number;
  style?: StyleProp<ViewStyle>;
};

export const LinearGradient = ({
  colors,
  angle,
  useAngle,
  locations,
  children,
  style,
}: React.PropsWithChildren<LinearGradientProps>) => {
  const background = `linear-gradient(${
    useAngle && angle !== undefined ? `${angle}deg, ` : ''
  }${colors.reduce((accumulator, color, index) => {
    const location = (index: number) =>
      (locations &&
        index <= locations.length &&
        ` ${locations[index] * 100}%`) ??
      '';

    return `${accumulator}${
      index === 1 ? location(0) : ''
    }, ${color}${location(index)}`;
  })})`;

  return (
    <View
      {...{children}}
      style={[
        {
          // @ts-expect-error background property doesn't exits for Views but since it will be translated to a div it will work
          background,
        },
        style,
      ]}
    />
  );
};
