import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import Svg, {Defs, Path, RadialGradient, Stop} from 'react-native-svg';

interface Props {
  colors: string[];
  style?: StyleProp<ViewStyle>;
}

const getOffsets = (n: number, p = 1.5) =>
  Array.from({length: n}, (_, i) => Math.pow(i / (n - 1), p));

export const FavoriteMoodlightGradient: React.FC<Props> = ({colors, style}) => {
  const offsets = getOffsets(colors.length);

  return (
    <Svg {...{style}} fill="none">
      <Path
        d="M536.851 311.253C544.277 246.163 551.45 177.256 534.515 118.76C517.579 60.2647 466.867 20.1398 426.022 53.199C398.829 75.2079 381.77 123.663 351.926 133.813C288.197 155.51 265.213 -12.4351 200.826 0.766949C155.584 10.0408 136.705 101.961 93.1425 124.961C59.3513 142.797 25.0794 114.705 -9.11774 121.644C-58.8287 131.722 -66.5353 217.07 -59.9138 272.498C-18.3891 705.201 455.921 729.115 536.851 311.253Z"
        fill="url(#paint0_radial_196_1532)"
      />

      <Path
        d="M201.629 4.68547L201.63 4.68543C215.664 1.80786 227.835 8.58167 239.665 21.7141C251.233 34.5563 261.675 52.6144 272.346 71.0708L273.16 72.4783C283.971 91.1693 295.079 110.194 307.616 123.108C313.923 129.605 320.793 134.776 328.411 137.544C336.124 140.346 344.39 140.604 353.214 137.6C361.479 134.789 368.654 129.399 375.119 122.837C381.582 116.278 387.517 108.358 393.235 100.261C395.118 97.5953 396.974 94.9181 398.818 92.2581C408.373 78.4765 417.61 65.1531 428.539 56.3082C438.09 48.5776 448.039 45.2458 457.814 45.2701C467.638 45.2945 477.582 48.7125 487.022 54.9791C505.964 67.5539 522.405 91.3184 530.672 119.873C547.332 177.417 540.333 245.432 532.895 310.644C492.687 517.946 355.357 614.534 223.925 606.844C92.446 599.152 -35.3131 486.974 -55.9321 272.116L-55.9365 272.07L-55.942 272.024C-59.2136 244.638 -58.9225 209.954 -51.7578 181.026C-44.5256 151.827 -30.7597 130.113 -8.32297 125.564L-8.32235 125.564C5.55357 122.748 19.4226 126.073 34.2914 129.637C36.5917 130.189 38.9159 130.746 41.2678 131.287C58.4112 135.231 76.722 138.151 95.0097 128.499L95.0101 128.498C106.716 122.318 116.502 111.666 125.234 99.4877C132.762 88.9896 139.725 77.0351 146.53 65.3531C147.625 63.4719 148.717 61.5979 149.806 59.7381C157.729 46.2092 165.528 33.4265 174.077 23.4535C182.629 13.4765 191.569 6.74766 201.629 4.68547Z"
        stroke="white"
        strokeOpacity="0.29"
        strokeWidth="8"
      />

      <Defs>
        <RadialGradient
          id="paint0_radial_196_1532"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(233.277 307.307) rotate(-174.006) scale(302.475 303.142)">
          {colors.map((color, index) => (
            <Stop key={index} offset={offsets[index]} stopColor={color} />
          ))}
        </RadialGradient>
      </Defs>
    </Svg>
  );
};
