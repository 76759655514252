import {CompositeScreenProps} from '@react-navigation/native';
import {StackScreenProps} from '@react-navigation/stack';
import {RehashType} from 'puffco-api-axios-client';
import React from 'react';
import {getTimeZone} from 'react-native-localize';
import {useSelector} from 'react-redux';

import {useSpinner} from '../../components';
import {Navigators, Screens, Strings, appColors} from '../../constants';
import {Context, Feature} from '../../contexts/context';
import {rehashApi} from '../../lib/api/apis';
import {useAppDispatch} from '../../lib/hooks';
import {useImmutableRemoteData} from '../../lib/hooks/useRemoteData';
import createStoryNavigator, {
  StoryNavigationOptions,
} from '../../lib/navigation/story';
import {appFlagsSelector, updateAppFlags} from '../../lib/redux/appFlagsSlice';
import {userIdSelector} from '../../lib/redux/userSlice';
import {ShareableStory} from '../../screens/Rehash/components/ShareableStory';
import {
  CommunityDabs,
  Props as CommunityDabsProps,
} from '../../screens/Rehash/screens/CommunityDabs';
import {
  CommunityIntro,
  Props as CommunityIntroProps,
} from '../../screens/Rehash/screens/CommunityIntro';
import {
  CommunityMoodlightRuntime,
  Props as CommunityMoodlightRuntimeProps,
} from '../../screens/Rehash/screens/CommunityMoodlightRuntime';
import {
  CreateAccount,
  Props as CreateAccountProps,
} from '../../screens/Rehash/screens/CreateAccount';
import {Empty, Props as EmptyProps} from '../../screens/Rehash/screens/Empty';
import {
  FavoriteDabTime,
  Props as FavoriteDabTimeProps,
} from '../../screens/Rehash/screens/FavoriteDabTime';
import {
  FavoriteDevice,
  Props as FavoriteDeviceProps,
} from '../../screens/Rehash/screens/FavoriteDevice';
import {
  FavoriteMoodlight,
  Props as FavoriteMoodlightProps,
} from '../../screens/Rehash/screens/FavoriteMoodlight';
import {Intro, Props as IntroProps} from '../../screens/Rehash/screens/Intro';
import {Outro, Props as OutroProps} from '../../screens/Rehash/screens/Outro';
import {
  UserDabs,
  Props as UserDabsProps,
} from '../../screens/Rehash/screens/UserDabs';
import {
  ViralMoodlight,
  Props as ViralMoodlightProps,
} from '../../screens/Rehash/screens/ViralMoodLight';
import {HomeEmulatedDrawerStackScreenProps} from './HomeDrawerNavigator';

export type RehashStoryParamList = {
  [Screens.RehashIntro]: IntroProps;
  [Screens.RehashEmpty]: EmptyProps;
  [Screens.RehashUserDabs]: UserDabsProps;
  [Screens.RehashFavoriteDevice]: FavoriteDeviceProps;
  [Screens.RehashFavoriteMoodlight]: FavoriteMoodlightProps;
  [Screens.RehashFavoriteDabTime]: FavoriteDabTimeProps;
  [Screens.RehashCommunityIntro]: CommunityIntroProps;
  [Screens.RehashCommunityDabs]: CommunityDabsProps;
  [Screens.RehashCommunityMoodlightRuntime]: CommunityMoodlightRuntimeProps;
  [Screens.RehashViralMoodlight]: ViralMoodlightProps;
  [Screens.RehashOutro]: OutroProps;
  [Screens.RehashCreateAccount]: CreateAccountProps;
};

export type RehashStoryScreenProps<T extends keyof RehashStoryParamList> =
  CompositeScreenProps<
    StackScreenProps<RehashStoryParamList, T>,
    HomeEmulatedDrawerStackScreenProps<typeof Navigators.RehashStory>
  >;

const RehashStory = createStoryNavigator<RehashStoryParamList>();

const typeToScreenProps: Record<
  RehashType,
  {
    name: keyof RehashStoryParamList;
    component: React.FC<any>;
    options?: StoryNavigationOptions;
  }
> = {
  [RehashType.Intro]: {name: Screens.RehashIntro, component: Intro},
  [RehashType.Empty]: {name: Screens.RehashEmpty, component: Empty},
  [RehashType.UserDabs]: {
    name: Screens.RehashUserDabs,
    component: UserDabs,
    options: {headerTintColor: appColors.black},
  },
  [RehashType.FavoriteDevice]: {
    name: Screens.RehashFavoriteDevice,
    component: FavoriteDevice,
  },
  [RehashType.FavoriteMoodlight]: {
    name: Screens.RehashFavoriteMoodlight,
    component: FavoriteMoodlight,
  },
  [RehashType.FavoriteDabTime]: {
    name: Screens.RehashFavoriteDabTime,
    component: FavoriteDabTime,
  },
  [RehashType.CommunityIntro]: {
    name: Screens.RehashCommunityIntro,
    component: CommunityIntro,
  },
  [RehashType.CommunityDabs]: {
    name: Screens.RehashCommunityDabs,
    component: CommunityDabs,
    options: {headerTintColor: appColors.black},
  },
  [RehashType.CommunityMoodlightRuntime]: {
    name: Screens.RehashCommunityMoodlightRuntime,
    component: CommunityMoodlightRuntime,
    options: {headerTintColor: appColors.black},
  },
  [RehashType.ViralMoodlight]: {
    name: Screens.RehashViralMoodlight,
    component: ViralMoodlight,
    options: {headerTintColor: appColors.black},
  },
  [RehashType.Outro]: {name: Screens.RehashOutro, component: Outro},
  [RehashType.CreateAccount]: {
    name: Screens.RehashCreateAccount,
    component: CreateAccount,
  },
};

const useLoadRehash = () => {
  const userId = useSelector(userIdSelector);
  const timezone = getTimeZone();

  return useImmutableRemoteData(
    {key: 'useRehash', userId, timezone},
    async () =>
      rehashApi.getRehash({timezone}).then(r =>
        r.data.items.map(item => {
          const {component: Component, ...screen} =
            typeToScreenProps[item.type];

          const component = item.shareable
            ? (props: any) => (
                <ShareableStory>
                  <Component {...props} />
                </ShareableStory>
              )
            : (props: any) => <Component {...props} />;

          return {...item, screen: {...screen, component}};
        }),
      ),
    {keepPreviousData: true},
  );
};

export const RehashStoryNavigator = () => {
  const {getFeatureIfEnabled} = Context.useContainer();

  const {hasSeenRehash} = useSelector(appFlagsSelector);

  const dispatch = useAppDispatch();

  const rehashConfig = getFeatureIfEnabled(Feature.Rehash);

  const rehash = useLoadRehash();

  useSpinner({
    isVisible: rehash.isLoading,
    text: Strings.LOADING_DATA,
    color: appColors.white,
  });

  React.useEffect(() => {
    if (!rehash?.data?.length || !rehashConfig?.label) return;

    if (hasSeenRehash?.[rehashConfig.label]) return;

    dispatch(
      updateAppFlags({
        hasSeenRehash: {...hasSeenRehash, [rehashConfig.label]: true},
      }),
    );
  }, [hasSeenRehash, rehash.data?.length]);

  if (!rehash?.data?.length) return;

  return (
    <RehashStory.Navigator>
      {rehash?.data.map(({screen, ...initialParams}) => (
        <RehashStory.Screen
          key={screen.name}
          {...{initialParams}}
          {...screen}
        />
      ))}
    </RehashStory.Navigator>
  );
};
