import {BaseRehashDto} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, Image, StyleSheet, View} from 'react-native';

import {outroHero, outroWaves} from '../../../assets/images';
import {ResponsiveText, SwitchableBackground} from '../../../components';
import {appColors} from '../../../constants';
import {Screens} from '../../../constants';
import {RehashStoryScreenProps} from '../../../navigation/navigators/RehashStoryNavigator';
import {RehashScreen} from '../components/RehashScreen';

export type Props = BaseRehashDto;

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashOutro> {}

const {height} = Dimensions.get('window');
const theme = {
  background: {color: appColors.black},
};

export const Outro: React.FC<ScreenProps> = ({route}) => {
  const {title, subtitle} = route.params;

  return (
    <RehashScreen
      as={SwitchableBackground}
      style={styles.container}
      background={theme.background}>
      <View style={styles.top}>
        <Image style={styles.hero} source={outroHero} />
      </View>

      <View style={styles.bottom}>
        <Image style={styles.waves} source={outroWaves} />

        <View style={styles.titleAndSubtitle}>
          <ResponsiveText size={88} style={styles.title}>
            {title}
          </ResponsiveText>

          <ResponsiveText size={16} style={styles.subtitle}>
            {subtitle}
          </ResponsiveText>
        </View>
      </View>
    </RehashScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  hero: {
    marginHorizontal: 'auto',
    resizeMode: 'contain',
    height: height * 0.3,
  },
  top: {
    marginBottom: 31,
  },
  bottom: {
    flex: 1,
  },
  waves: {
    marginBottom: 47,
  },
  titleAndSubtitle: {
    paddingHorizontal: 40,
  },
  title: {
    color: appColors.white,
    fontFamily: 'Roboto-Bold',
    marginBottom: 27,
    lineHeight: 83,
  },
  subtitle: {color: appColors.white, opacity: 0.5, lineHeight: 22},
});
