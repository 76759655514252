import React from 'react';
import {View} from 'react-native';
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';

import {StyledIcon} from '../../components';
import {appColors} from '../../constants';
import styled from '../../lib/styled';
import {addOpacityToColorHex} from '../../lib/utilityFunctions';

export interface Props {
  index: number;
  length: number;
  progress: SharedValue<number>;
  isDark?: boolean;
  isPaused?: boolean;
  visible?: boolean;
  onCloseButtonPress: () => void;
  onPlaybackButtonPress: () => void;
}

export const StoriesHeader: React.FC<Props> = ({
  index,
  length,
  progress,
  isDark,
  isPaused,
  visible = true,
  onCloseButtonPress,
  onPlaybackButtonPress,
}) => {
  const completedColor = isDark ? appColors.black : appColors.white;
  const uncompletedColor = addOpacityToColorHex(completedColor, 0.5);

  const activeStyle = useAnimatedStyle(() => ({
    width: `${interpolate(progress.value, [0, 1], [0, 100])}%`,
    backgroundColor: completedColor,
  }));

  return (
    <View style={!visible && {opacity: 0}}>
      <Segments>
        {Array.from({length}, (_, segmentIndex) => (
          <Segment
            key={segmentIndex}
            style={{
              backgroundColor:
                segmentIndex < index ? completedColor : uncompletedColor,
            }}>
            {segmentIndex === index && <ActiveSegment style={activeStyle} />}
          </Segment>
        ))}
      </Segments>

      <Header>
        <ControlIcon
          size={16}
          name={isPaused ? 'playOutlined' : 'pause'}
          color={completedColor}
          style={{
            borderColor: uncompletedColor,
          }}
          onPress={onPlaybackButtonPress}
        />

        <ControlIcon
          name="close"
          size={16}
          color={completedColor}
          style={{
            borderColor: uncompletedColor,
          }}
          onPress={onCloseButtonPress}
        />
      </Header>
    </View>
  );
};

const Header = styled(View)({
  marginTop: 12,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 8,
});

const Segments = styled(View)({
  flexDirection: 'row',
  height: 5,
  gap: 5,
  marginBottom: 12,
});

const Segment = styled(View)({
  flex: 1,
  borderRadius: 100,
  overflow: 'hidden',
});

const ActiveSegment = styled(Animated.View)({
  height: '100%',
});

const ControlIcon = styled(StyledIcon)({
  width: 44,
  height: 44,
  borderRadius: 22,
  borderWidth: 1,
});
