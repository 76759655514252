import React from 'react';
import {Path, Svg, SvgProps} from 'react-native-svg';

export interface Props extends SvgProps {
  size?: number;
}

export const ShareIcon = ({size, style, ...props}: Props) => (
  <Svg
    width="25"
    height="32"
    viewBox="0 0 25 32"
    fill="none"
    {...{props}}
    style={[{width: size, height: size}, style]}>
    <Path
      d="M2.88482 32C2.0628 32 1.37649 31.7445 0.825893 31.2335C0.275298 30.7224 0 30.0855 0 29.3225V13.1317C0 12.3688 0.275298 11.7318 0.825893 11.2208C1.37649 10.7098 2.0628 10.4542 2.88482 10.4542H6.66205V12.1116H2.88482C2.60982 12.1116 2.35789 12.2178 2.12902 12.4302C1.90015 12.6427 1.78571 12.8765 1.78571 13.1317V29.3225C1.78571 29.5778 1.90015 29.8116 2.12902 30.024C2.35789 30.2364 2.60982 30.3426 2.88482 30.3426H22.1152C22.3902 30.3426 22.6421 30.2364 22.871 30.024C23.0999 29.8116 23.2143 29.5778 23.2143 29.3225V13.1317C23.2143 12.8765 23.0999 12.6427 22.871 12.4302C22.6421 12.2178 22.3902 12.1116 22.1152 12.1116H18.3379V10.4542H22.1152C22.9372 10.4542 23.6235 10.7098 24.1741 11.2208C24.7247 11.7318 25 12.3688 25 13.1317V29.3225C25 30.0855 24.7247 30.7224 24.1741 31.2335C23.6235 31.7445 22.9372 32 22.1152 32H2.88482ZM11.6071 21.2271V3.18421L7.85714 6.66468L6.5933 5.48215L12.5 0L18.4067 5.48215L17.1429 6.66468L13.3929 3.18421V21.2271H11.6071Z"
      fill="white"
    />
  </Svg>
);
