import React from 'react';
import {useSelector} from 'react-redux';
import {useUpdateEffect} from 'react-use';

import {DeviceAttributes} from '../ble2/v2/PeakDevice/IPeakDevice';
import {connectedPeakSelector, updateDevice} from '../redux/bleSlice';
import {getDeviceFromPeak} from '../utilityFunctions/getDeviceFromPeak';
import {useAppDispatch} from './useAppDispatch';

interface TypeMap {
  approxDabsRemainingCount: DeviceAttributes['approxDabsRemaining'];
  battery: DeviceAttributes['batteryLevel'];
  batteryChargeEstTimeToFull: DeviceAttributes['chargeEstimatedTimeToFull'];
  batteryChargeState: DeviceAttributes['chargeState'];
  chamberType: DeviceAttributes['chamberType'];
  currentTemp: DeviceAttributes['currentTemperature'];
  dailyDabs: DeviceAttributes['dabsPerDay'];
  faultEndIndex: DeviceAttributes['faultEndIndex'];
  heatCycleSelect: DeviceAttributes['selectedHeatCycle'];
  hits: DeviceAttributes['totalHeatCycles'];
  state: DeviceAttributes['operatingState'];
  stateElapsedTime: DeviceAttributes['stateElapsedTime'];
  stateTotalTime: DeviceAttributes['stateTotalTime'];
  targetTemp: DeviceAttributes['targetTemperature'];
}

type AttributeKey = keyof TypeMap;

type ReturnValueType<T extends AttributeKey> = TypeMap[T];

const getters = {
  approxDabsRemainingCount: (a: Partial<DeviceAttributes>) =>
    a.approxDabsRemaining,
  battery: (a: Partial<DeviceAttributes>) => a.batteryLevel,
  batteryChargeEstTimeToFull: (a: Partial<DeviceAttributes>) =>
    a.chargeEstimatedTimeToFull,
  batteryChargeState: (a: Partial<DeviceAttributes>) => a.chargeState,
  chamberType: (a: Partial<DeviceAttributes>) => a.chamberType,
  currentTemp: (a: Partial<DeviceAttributes>) => a.currentTemperature,
  dailyDabs: (a: Partial<DeviceAttributes>) => a.dabsPerDay,
  faultEndIndex: (a: Partial<DeviceAttributes>) => a.faultEndIndex,
  heatCycleSelect: (a: Partial<DeviceAttributes>) => a.selectedHeatCycle,
  hits: (a: Partial<DeviceAttributes>) => a.totalHeatCycles,
  state: (a: Partial<DeviceAttributes>) => a.operatingState,
  targetTemp: (a: Partial<DeviceAttributes>) => a.targetTemperature,
  stateElapsedTime: (a: Partial<DeviceAttributes>) => a.stateElapsedTime,
  stateTotalTime: (a: Partial<DeviceAttributes>) => a.stateTotalTime,
} as const;

export const useWatchDevice = <T extends AttributeKey>(
  key: T,
  active = true,
  interval = 1000,
): ReturnValueType<T> => {
  const peak = useSelector(connectedPeakSelector);

  const dispatch = useAppDispatch();

  const getter = React.useMemo(() => getters[key], [key]);

  const [state, setState] = React.useState(
    peak?.attributes ? getter(peak.attributes) : undefined,
  );

  React.useEffect(() => {
    if (!active) return;

    const id = setInterval(() => {
      if (!peak) return;

      setState(peak?.attributes ? getter(peak.attributes) : undefined);
    }, interval);

    return () => clearInterval(id);
  }, [peak, setState, interval, active, getter]);

  useUpdateEffect(() => {
    if (!peak) return;
    if (!active) return;

    dispatch(updateDevice(getDeviceFromPeak(peak)));
  }, [state, active]);

  return state as ReturnValueType<T>;
};
