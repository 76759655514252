import React from 'react';
import {Linking, ScrollView, StyleSheet, View} from 'react-native';

import {
  Constants,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../../../constants';
import {isResolvedEntryLink, resolveAssetUrl} from '../../../../lib/api';
import {useAdaptiveSafeArea} from '../../../../lib/hooks/useAdaptiveSafeArea';
import {DeviceEducationNavigatorScreenProps} from '../../../../navigation/navigators/HomeDrawerNavigator';
import {analytics} from '../../../../src/services/analytics';
import {convertName} from '../../../../src/services/analytics/converters';
import {ErrorContainer} from '../../../components/contentful';
import {useContactSupport} from '../../../support/useContactSupport';
import {Description} from '../../components/Description';
import {DeviceCarouselSelector} from '../../components/DeviceCarouselSelector';
import {DeviceSupportHeader} from '../../components/DeviceSupportHeader';
import {DeviceVideoCarousel} from '../../components/DeviceVideoCarousel';
import {Footer} from '../../components/Footer';
import {LoadingContainer} from '../../components/LoadingContainer';
import {SupportItemSection} from '../../components/SupportItemSection';
import {useDeviceSelection} from './useDeviceSelection';

interface ScreenProps
  extends DeviceEducationNavigatorScreenProps<typeof Screens.DeviceSupport> {}

export const DeviceSupportScreen: React.FC<ScreenProps> = ({navigation}) => {
  const {selected, select, devices, loading, error} = useDeviceSelection();

  useAdaptiveSafeArea();

  React.useEffect(() => {
    if (!selected) return;

    analytics.trackEvent('select content', {
      content_type: 'support_device',
      content_id: convertName(selected.fields.name),
    });
  }, [selected]);

  const contactSupport = useContactSupport();

  if (loading) return <LoadingContainer />;

  if (error || !selected) return <ErrorContainer />;

  const documents = selected?.fields.documents?.filter(isResolvedEntryLink);
  const videos = selected?.fields.videos?.filter(isResolvedEntryLink);
  const guides = selected?.fields.guides?.filter(isResolvedEntryLink);
  const manual = selected?.fields.manual;

  return (
    <View style={{flex: 1, flexBasis: 0, backgroundColor: appColors.black}}>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.layout}>
        <DeviceSupportHeader
          style={styles.headerImage}
          source={{
            uri: resolveAssetUrl(selected.fields.backgroundImage),
          }}
        />

        <DeviceCarouselSelector
          {...{devices, selectedDevice: selected}}
          onPress={newDevice => {
            if (newDevice.sys.id !== selected.sys.id) select(newDevice);
          }}
        />

        <Description
          title={selected.fields.name}
          description={selected.fields.description}
        />

        {!!videos && <DeviceVideoCarousel {...{videos}} />}

        {!!guides && (
          <>
            <SupportItemSection
              title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.WALKTHROUGHS}
              items={[
                ...(guides ?? []).map(guide => {
                  return {
                    title: guide.fields.name,
                    onPress: async () => {
                      navigation.navigate(Navigators.Guide, {
                        screen: Screens.Guide,
                        params: {
                          guide,
                        },
                      });

                      analytics.trackEvent('select content', {
                        content_type: 'support_guide',
                        content_id: convertName(
                          `${selected.fields.name}-${guide.fields.name}`,
                        ),
                      });
                    },
                  };
                }),
              ]}
            />
          </>
        )}

        <SupportItemSection
          title={Strings.HELP.toUpperCase()}
          items={[
            ...(documents ?? []).map(document => {
              return {
                title: document.fields.title,
                onPress: () => {
                  navigation.navigate(Screens.DeviceDocument, {
                    document,
                  });

                  analytics.trackEvent('select content', {
                    content_type: 'support_document',
                    content_id: convertName(
                      `${selected.fields.name}-${document.fields.title}`,
                    ),
                  });
                },
              };
            }),
            {
              title: Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.FAQ.toUpperCase(),
              onPress: () => {
                navigation.navigate(Screens.Faq, {
                  referenceId: selected.sys.id,
                  selected,
                });

                analytics.trackEvent('select content', {
                  content_type: 'support_document',
                  content_id: convertName(
                    `${selected.fields.name}-${Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.FAQ.toUpperCase()}`,
                  ),
                });
              },
            },
          ]}
        />

        <Footer
          title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.FOOTER}
          style={styles.footer}
          onInstructionManualPress={() => {
            if (!manual) return;

            const url = resolveAssetUrl(manual);

            if (Constants.IS_WEB) {
              Linking.openURL(url);
            } else {
              navigation.navigate(Screens.UserManual, {
                source: {uri: url},
              });
            }

            analytics.trackEvent('select content', {
              content_type: 'button',
              content_id: convertName(`${selected.fields.name}-manual`),
            });
          }}
          onContactSupportPress={() => {
            contactSupport();

            analytics.trackEvent('select content', {
              content_type: 'button',
              content_id: convertName(
                `${selected.fields.name}-support-contact`,
              ),
            });
          }}
        />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  layout: {
    fontFamily: 'Roboto-Regular',
    flex: 1,
    backgroundColor: appColors.black,
  },
  headerImage: {
    marginBottom: 48,
  },
  footer: {
    paddingBottom: 72,
    marginTop: 24,
  },
});
