import {View} from 'react-native';

import styled from '../lib/styled';

export const RowContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 8,
});
