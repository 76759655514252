import React from 'react';

import {useStoryNavigation} from '../../../lib/navigation/story';
import {analytics} from '../../../src/services/analytics';
import {Shareable, Props as ShareableStoryProps} from './Shareable';

interface Props
  extends Omit<
    ShareableStoryProps,
    'onShareCancel' | 'onShareEnd' | 'onShareStart'
  > {}

export const ShareableStory: React.FC<
  React.PropsWithChildren<Props>
> = props => {
  const navigation = useStoryNavigation();

  return (
    <Shareable
      onShareCancel={navigation.resume}
      onShareEnd={() => {
        navigation.resume();
        analytics.trackEvent('share', {content_type: 'rehash'});
      }}
      onShareStart={navigation.pause}
      {...props}
    />
  );
};
