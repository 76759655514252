import {
  EventProperties,
  EventType,
  HeatCycleEventProperties,
  LanternEventProperties,
} from '../event-properties';
import {UserProperties} from '../user-properties';
import {AnalyticsService} from './analytics';

type Layer = 'heat cycle';

type LayerProperties = {
  'heat cycle': HeatCycleEventProperties;
  lantern: LanternEventProperties;
};

const config = {
  'heat cycle': [
    'heat cycle start',
    'heat cycle add temperature',
    'heat cycle add time',
    'heat cycle end',
  ],
  lantern: ['lantern on', 'lantern off'],
} as const;

const layers = Object.entries(config).reduce<{
  [k in EventType]?: {layer: {name: Layer; length: number}; order: number};
}>((previous, [layer, events]) => {
  events.forEach((event, index, initial) => {
    previous[event] = {
      layer: {name: layer as Layer, length: initial.length},
      order: index,
    };
  });

  return previous;
}, {});

type Setter<L extends Layer, E extends EventType> = (
  properties: LayerProperties[L],
  values: EventProperties[E],
) => Partial<LayerProperties[L] | EventProperties[E]>;

export class DataLayerAnalyticsService extends AnalyticsService {
  private readonly map = new Map<Layer, Record<string, number | string>>();

  async trackEvent<E extends EventType>(
    type: E,
    event: EventProperties[E],
    userProperties?: Partial<UserProperties>,
    setter: Setter<Layer, E> = (properties, values) => ({
      ...properties,
      ...values,
    }),
  ): Promise<void> {
    const found = layers[type];

    if (!found) {
      return super.trackEvent(type, event, userProperties);
    }

    const properties = this.map.get(
      found.layer.name,
    ) as LayerProperties[typeof found.layer.name];

    this.map.set(
      found.layer.name,
      setter(properties ?? {}, event) as Record<string, number | string>,
    );

    const data = {...(this.map.get(found.layer.name) ?? event), ...event};

    super.trackEvent(type, data, userProperties);

    if (found.order === found.layer.length - 1)
      this.map.delete(found.layer.name);
  }
}
