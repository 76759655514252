import {CommunityDabsDto} from 'puffco-api-axios-client';
import React from 'react';
import {Image, View} from 'react-native';

import {communityDabs} from '../../../assets/images';
import {
  ResponsiveText,
  RowContainer,
  SwitchableBackground,
} from '../../../components';
import {appColors} from '../../../constants';
import {Screens} from '../../../constants';
import {formatNumber} from '../../../lib/formatNumber';
import styled from '../../../lib/styled';
import {addOpacityToColorHex} from '../../../lib/utilityFunctions';
import {RehashStoryScreenProps} from '../../../navigation/navigators/RehashStoryNavigator';
import {BoldText, MediumText} from '../components';
import {RehashScreen} from '../components/RehashScreen';

export interface Props extends CommunityDabsDto {}

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashCommunityDabs> {}

const theme = {
  background: {color: appColors.white},
};

export const CommunityDabs: React.FC<ScreenProps> = ({route}) => {
  const {title, subtitle, dabCount, rank} = route.params;

  return (
    <RehashScreen
      as={SwitchableBackground}
      background={theme.background}
      contentStyle={{position: 'relative'}}>
      <Image
        source={communityDabs}
        style={{
          position: 'absolute',
          height: '110%',
          width: '130%',
          top: -40,
          alignSelf: 'center',
          resizeMode: 'contain',
          flex: 1,
        }}
      />

      <View
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: 1,
        }}>
        <Title size={24}>{title}</Title>

        <ContentContainer>
          <CountContainer>
            <DabCountText size={80}>{formatNumber(dabCount, 1)}</DabCountText>

            <Plus size={48}>+</Plus>
          </CountContainer>

          {!!rank && (
            <Subtitle size={18}>
              {subtitle}{' '}
              <Rank>
                {rank}
                {'%'}
              </Rank>
            </Subtitle>
          )}
        </ContentContainer>
      </View>
    </RehashScreen>
  );
};

const Title = styled(MediumText)({
  color: addOpacityToColorHex(appColors.black, 0.5),
  textAlign: 'center',
  marginTop: 28,
});

const ContentContainer = styled(View)({
  marginBottom: 36,
  alignItems: 'center',
});

const Subtitle = styled(ResponsiveText)({
  color: addOpacityToColorHex(appColors.black, 0.5),
});

const Rank = styled(BoldText)({
  color: appColors.black,
});

const CountContainer = styled(RowContainer)({
  justifyContent: 'center',
  gap: 10,
});

const DabCountText = styled(BoldText)({
  color: appColors.black,
  textTransform: 'uppercase',
});

const Plus = styled(BoldText)({
  color: '#F39D1B',
});
