import {useIsFocused} from '@react-navigation/native';
import {isAxiosError} from 'axios';
import {revisionStringToNumber} from 'pikaparam';
import React from 'react';
import {ScrollView, View} from 'react-native';
import {getBatteryLevel} from 'react-native-device-info';
import {useSelector} from 'react-redux';

import {ImgBackground, StyledButton, useSpinner} from '../../components';
import {
  Constants,
  ErrorMessages,
  Messages,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {IPeakDevice} from '../../lib/ble2/v2/PeakDevice/IPeakDevice';
import {useGetLatestOtaFile} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {connectedPeakSelector} from '../../lib/redux/bleSlice';
import styled from '../../lib/styled';
import {HomeEmulatedDrawerStackScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {Alert} from '../../shims/alert';
import {BodyContainer, BodyText, HeaderText} from './components';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.FirmwareInfo
>;

export const FirmwareInfoScreen = ({navigation}: ScreenProps) => {
  useAdaptiveSafeArea();

  const isFocused = useIsFocused();

  const peak = useSelector(connectedPeakSelector);
  const [{value, error, loading}, getOta] = useGetLatestOtaFile();

  const newestFirmwareVersion = value?.version;

  useSpinner({
    isVisible: loading,
    text: 'Connecting',
    props: {color: appColors.white},
    color: appColors.white,
  });

  React.useEffect(() => {
    if (!isFocused) return;
    if (!peak?.serialNumber) return navigation.goBack();

    getOta(peak.serialNumber);
  }, [isFocused, navigation, getOta, peak?.serialNumber]);

  const checkBatteryLevels = async (peak: IPeakDevice) => {
    const peakBattery = await peak.readDeviceBattery();

    if (peakBattery <= 10) return false;

    const phoneBattery = await getBatteryLevel();

    return phoneBattery >= 0.1;
  };

  const getHeaderText = (error: Error) => {
    if (!isAxiosError(error)) return 'Error';

    return ErrorMessages.NETWORK_REQUEST_FAILED;
  };

  const getBodyText = (error: Error) => {
    if (!isAxiosError(error)) return ErrorMessages.OTA_OFFLINE_ERROR;

    return Messages.UNABLE_TO_CONNECT_TO_SERVER;
  };

  const startUpdate = async () => {
    if (!peak) {
      return Alert.alert(
        'Peak Not Connected',
        'Please make sure your device is connected to initiate update.',
      );
    }

    const batteryOk = await checkBatteryLevels(peak);

    Alert.alert(
      'Check Battery',
      "Do not continue unless: Phone and Peak is both above 10% battery.\nPlease make sure your phone's Bluetooth is ONLY connected to the device before performing firmware update",
      [
        {
          text: 'OK',
          onPress: () => {
            if (!batteryOk) return navigation.goBack();
            if (!peak.connected) return navigation.goBack();

            navigation.navigate(Screens.FirmwareUpdating, {});
          },
        },
      ],
    );
  };

  return (
    <ImgBackground>
      {!loading && !!peak && (
        <SafeAreaContainer>
          {error ? (
            <ContentContainer>
              <Body>
                <HeaderText style={{textTransform: 'capitalize'}}>
                  {getHeaderText(error)}
                </HeaderText>

                <BodyText>{getBodyText(error)}</BodyText>
              </Body>

              <Button
                title={Strings.TRY_AGAIN}
                onPress={() => {
                  if (!peak.serialNumber) return;
                  getOta(peak.serialNumber);
                }}
              />
            </ContentContainer>
          ) : value &&
            peak.softwareRevision &&
            revisionStringToNumber(value.version) >
              revisionStringToNumber(peak.softwareRevision) ? (
            <ContentContainer>
              <Body>
                <HeaderText>Update Available</HeaderText>

                <BodyText>Current Version: {peak.softwareRevision}</BodyText>

                <BodyText style={{marginTop: 2}}>
                  Available Version: {newestFirmwareVersion}
                </BodyText>

                <ScrollView
                  style={{flex: 1, marginVertical: 15}}
                  contentContainerStyle={{
                    height: Constants.IS_WEB ? 0 : undefined,
                  }}>
                  <BodyText style={{color: appColors.white50}}>
                    {value.releaseNotes}
                  </BodyText>
                </ScrollView>
              </Body>

              <Button title="Start Update" onPress={startUpdate} />
            </ContentContainer>
          ) : (
            <Body>
              <HeaderText>Device Up to Date</HeaderText>

              <BodyText>Current Version: {peak.softwareRevision}</BodyText>
            </Body>
          )}
        </SafeAreaContainer>
      )}
    </ImgBackground>
  );
};

const Body = styled(BodyContainer)({flex: 1});

const SafeAreaContainer = styled(SafeAreaView)({flex: 1});

const Button = styled(StyledButton)({
  alignSelf: 'center',
  marginBottom: 30,
});

const ContentContainer = styled(View)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
});
