import {ResponsiveText} from '../../../components';
import {appColors} from '../../../constants';
import styled from '../../../lib/styled';

export const MediumText = styled(ResponsiveText)({
  fontFamily: 'Roboto-Medium',
  color: appColors.white,
});

export const BoldText = styled(ResponsiveText)({
  fontFamily: 'Roboto-Bold',
  color: appColors.white,
});

export const RegularText = styled(ResponsiveText)({
  fontFamily: 'Roboto-Regular',
});
