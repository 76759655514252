import React from 'react';

export const formatText = (
  text: string,
  replace: (children: string) => React.ReactNode,
) => {
  const regex = /<i\b[^>]*>(.*?)<\/i>/gi;

  const match = regex.exec(text);

  if (match === null) return text;

  const until = match.input.slice(0, match.index);
  const after = match.input.slice(match.index + match[0].length);

  const child = replace(match[1]);

  return React.Children.map([until, child, after], child => child);
};
