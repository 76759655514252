import React from 'react';
import {Helmet} from 'react-helmet';
import {StatusBar as NativeStatusbar} from 'react-native';

import {ScreenNames} from '../constants';
import {StatusBarStyle} from '../lib/types';
import {useTheme} from '../lib/useTheme';

export interface AppStatusBarProps {
  activeRouteName?: ScreenNames;
}

export const AppStatusBar: React.FC<AppStatusBarProps> = ({
  activeRouteName,
}) => {
  const {statusBarTheme} = useTheme();

  const props = activeRouteName
    ? statusBarTheme[`${activeRouteName}StatusBar`] || statusBarTheme.themed
    : statusBarTheme.themed;

  return <StatusBar {...props} />;
};

export interface StatusBarProps extends StatusBarStyle {}

export const StatusBar: React.FC<StatusBarProps> = ({
  barStyle,
  backgroundColor,
}) => (
  <>
    <Helmet>
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content={barStyle === 'dark-content' ? 'black-translucent' : 'default'}
      />
    </Helmet>

    <NativeStatusbar {...{barStyle, backgroundColor}} />
  </>
);
