import {Vapor} from '../lib/types';

export const interpolate = (
  template: string,
  variables: {[x: string]: any},
) => {
  return template.replace(new RegExp('{([^{]+)}', 'g'), (_, varName) => {
    return variables[varName];
  });
};

export const ErrorMessages = {
  OTA_OFFLINE_ERROR:
    'Connect to the internet to get the latest firmware update.',
  NETWORK_ERROR: 'There is a network issue. Please try again later.',
  NETWORK_REQUEST_FAILED: 'Network request failed',
  LOGOUT_ERROR: 'An error occurred when trying to logout. Please try again.',
  PERMISSIONS_REQUIRED: 'Permissions required',
  INVALID_SESSION: 'Please log in again',
  FORBIDDEN_RESOURCE: 'Forbidden resource',

  // Custom Dab Value
  CUSTOM_DAB_LIMIT: 'Cannot exceed over {dabLimit} dabs',
  CUSTOM_NEGATIVE_DAB: 'Dab value cannot be negative',

  // login & register
  INVALID_CREDENTIALS:
    'We cannot find an account with this email and password combination. Please check again',
  INVALID_EMAIL: 'Please enter a valid email',
  INVALID_EMAIL2: 'Invalid email address',
  INVALID_EMAIL3: 'Invalid Email',
  INVALID_DATE: 'Invalid Date.',
  INVALID_NUMBER: 'Invalid Number',
  EMAIL_NOT_VERIFIED:
    'You have not verified your email yet, please check your inbox',
  AGE_NOT_MET: 'Age requirement is not met.',
  INVALID_PHONE_NUMBER: 'Phone number is not valid',
  REQUIRED_PASSWORD: 'Password is required',
  REQUIRED_FIRST_NAME: 'First name is required',
  REQUIRED_LAST_NAME: 'Last name is required',
  REQUIRED_NAME: 'Name is required',
  REQUIRED_EMAIL: 'Email is required',
  REQUIRED_PHONE_NUMBER: 'Phone number is required',
  REQUIRED_BIRTHDAY: 'DoB is required',
  REQUIRED_USERNAME: 'Username is required',
  REQUIRE_USERNAME_MIN_LENGTH: 'At least 5 characters long',
  REQUIRE_USERNAME_MAX_LENGTH: 'At most 18 characters long',
  PASSWORDS_MATCH_ERROR: 'Your passwords do not match',
  PASSWORD_FORMAT_ERROR:
    'At least one capital letter, one lower case letter and a number',
  PASSWORD_MINIMUM_ERROR: 'At least 8 characters',
  REQUIRED_PASSWORD_CONFIRMATION: 'Password confirmation is required',
  EMAIL_ALREADY_USED: 'Email already in use! Please use a different one',

  // change password
  INVALID_USER_AUTHORIZATION: 'User not authorized to make changes.',
  INVALID_PASSWORD_CREDENTIALS:
    'Invalid current password credentials. Please try again. ',

  //change name
  REQUIRED_FIRSTNAME: 'First name is required',
  REQUIRED_LASTNAME: 'Last name is required',

  //Device name
  DEVICE_NAME_DISCONNECTED: 'Not connected to the Peak',
  DEVICE_NAME_EMPTY: 'Device name is empty',
  DEVICE_NAME_LONG: 'Device name is too long',

  //Profile name
  PROFILE_NAME_EMPTY: 'Profile name is empty',
  PROFILE_NAME_LONG: 'Profile name is too long',

  // Share
  HEAT_PROFILE_NOT_FOUND: 'The heat profile could not be found on the server.',
  SHARE_NOT_FOUND: 'Sorry, the shared data could not be found.',

  INVALID_VERIFY_LINK: 'The email verification link is invalid',
  BAD_REQUEST: 'Bad Request',

  //ConnectScreen
  DEVICE_NOT_FOUND_TITLE: 'Peak Not Found',
  DEVICE_NOT_FOUND_MSG:
    'We were unable to complete the connection with your device. Make sure the device is in pairing mode by clicking and holding the power button until the logo glows blue.',
  CONNECTION_ERROR_TITLE: 'Connect Failed',
  CONNECTION_ERROR_MSG:
    "We were unable to complete the connection with your device. Make sure the device is in pairing mode by clicking and holding the power button until the logo glows blue. If that doesn't help, navigate into your phone's bluetooth settings and forget the device.",
  CONNECTION_CANCELED_ERROR_TITLE: 'Cancelled Connection',
  CONNECTION_CANCELED_ERROR_MSG:
    'The connection was cancelled. Please try again.',
  PAIRING_ERROR_TITLE: 'Pairing Error',
  PAIRING_ERROR_MSG: 'Make sure your device is in pairing mode and try again.',
  LOCATION_ACCESS_DENIED_TITLE: 'Location Permission Required',
  LOCATION_ACCESS_DENIED_12_MSG:
    'Select PRECISE and WHILE USING THE APP for the best experience.',
  LOCATION_ACCESS_DENIED_PRE_12_MSG:
    'Press try again and "Allow only while using the app" when prompted.',
  BLUETOOTH_PERMISSION_DENIED_TITLE: 'Bluetooth Permissions Required',
  BLUETOOTH_PERMISSION_DENIED_MSG:
    'Press Continue and ALLOW access to Nearby Devices.',
  BLUETOOTH_PERMISSION_DENIED_WEB_MSG:
    'Select Continue and allow access to Bluetooth in your phone settings.',
  LOCATION_SERVICES_DISABLED_TITLE: 'Location Services Disabled',
  LOCATION_SERVICES_DISABLED_MSG:
    'Please enable location services in your phone settings and try again.',
  BLUETOOTH_SERVICES_DISABLED_TITLE: 'Bluetooth Disabled',
  BLUETOOTH_SERVICES_DISABLED_MSG:
    'Please enable Bluetooth in your phone settings and control center.',
  IOS_FORGOTTEN_DEVICE_TITLE: 'Device Unpaired',
  IOS_FORGOTTEN_DEVICE_MSG:
    'Forget the device in your Bluetooth Settings and try pairing again.',
  BONDING_ERROR_TITLE: 'Bonding Failed',
  BONDING_ERROR_MSG:
    'Forget the device in your Bluetooth Settings and try pairing again. Make sure your peak is in pairing mode.',
  BOOTLOADER_TITLE: 'Firmware Update Pending',
  BOOTLOADER_MSG:
    'The firmware update was previously aborted. You need finish the update to use your Peak.',

  // Default
  SOMETHING_WENT_WRONG: 'Something went wrong.',
};

export const Alerts = {
  ACCOUNT_NOT_LOGGED_IN: 'Account Not Logged In',
  ACCOUNT_UPDATED: 'Account Updated',
  ADVANCED_METRICS_SHARE_TITLE: 'Share usage metrics',
  ADVANCED_METRICS_SHARE_MESSAGE:
    'You are about to share detailed metrics on your device usage',
  CONFIRM_LOGOUT: 'Log Out',
  CONNECT_TO_DEVICE: 'Connect to a Peak device to access this feature',
  SUCCESS: 'Success',
  CONFIRM_ARCHIVE_SAVE_BODY: 'Heat profile saved to archives list.',
  CONFIRM_DELETE_ACCOUNT_TITLE: 'DELETE ACCOUNT',
  CONFIRM_DELETE_ACCOUNT_BODY: 'Are you sure you want to delete your account?',
  CONFIRM_DELETE_MOOD_LIGHT: 'Are you sure you want to delete {moodLight}?',
  CONFIRM_DELETE_PROFILE_BODY: 'Are you sure you want to delete {profileName}?',
  CONFIRM_DELETION: 'Confirm Deletion',
  CONFIRM_MOODLIGHT_SAVE_BODY: 'Mood light saved to custom mood lights list.',
  CONFIRM_WHITEN_PROFILES:
    'There are heat profiles with this mood light attached. Deleting will set these profiles back to white color. \n\nAffected profiles: {allNames}',
  DEVICE_OUT_OF_RANGE_TITLE: 'Device Out of Range',
  DEVICE_OUT_OF_RANGE_BODY: 'Make sure your device is on and within range.',
  DISCONNECTED_TITLE: 'Disconnected',
  DISCONNECTED_BODY: 'Device has disconnected',
  PEAK_NOT_CONNECTED_TITLE: 'Peak not connected',
  END_SESSION_TITLE: 'End Session',
  END_SESSION_BODY: 'Are you sure you want to end the current session?',
  INVALID_LOGIN: 'Invalid Login',
  MAX_HEAT_PROFILES_REACHED_TITLE: 'Maximum heat profiles reached',
  MAX_HEAT_PROFILES_REACHED_MESSAGE:
    "You've reached the limit! Try deleting or editing an existing profile.",
  MOOD_ASSIGNED: 'Mood Assigned',
  MOOD_ASSIGNED_BODY:
    'The mood profile "{moodName}" was assigned to the heat profile "{profileName}".',
  MOOD_DELETE_ERROR_TITLE: 'Error deleting mood light',
  MOOD_DELETED: 'Mood Deleted',
  MOOD_DELETED_BODY: 'The mood profile "{name}" is now deleted.',
  MOOD_DOWNLOADED: 'Mood Downloaded',
  MOOD_DOWNLOADED_BODY:
    'The mood profile "{name}" is now available on your account.',
  MOOD_SHARE_DOWNLOADED_BODY:
    '"{name}" has been downloaded to your Mood Lights.',
  MOOD_DUPLICATED: 'Mood Duplicated',
  MOOD_DUPLICATED_BODY:
    'The mood profile "{name}" has been duplicated on your account.',
  MOOD_LIGHT_ASSIGN_ERROR_TITLE: 'Error assigning mood light',
  MOOD_LIGHT_CREATE_ERROR_TITLE: 'Error creating mood light',
  MOOD_LIGHT_GET_SHARE_ERROR_TITLE: 'Error finding mood light',
  MOOD_LIGHT_LOGIN_CREATE_MESSAGE: 'Log in to create a Mood Light.',
  MOOD_LIGHT_SHARE_ERROR_TITLE: 'Error sharing mood light',
  MOOD_LIGHT_SHARE_TITLE: 'Share Mood Light',
  MOOD_LIGHT_LOGIN_SHARE_MESSAGE: 'Log in to share Mood Light.',
  MOOD_LIGHT_DOWNLOAD_TITLE: 'Download Mood Light',
  MOOD_LIGHT_DOWNLOADED_SAVE_TITLE: 'Slow down Picasso!',
  MOOD_LIGHT_DOWNLOADED_SAVE_MESSAGE:
    'Edits to downloaded moods will be saved as a new custom mood profile.',
  MOOD_LIGHT_LOGIN_DOWNLOAD_MESSAGE: 'Log in to download Mood Light.',
  MOOD_LIGHT_SAVE_ERROR_TITLE: 'Error saving mood light',
  MOOD_LIGHT_UPDATE_MESSAGE:
    'Update to the latest firmware to access Mood Lights.',
  PROFILE_SHARE_TITLE: 'Share Heat Profile',
  PROFILE_SHARE_ERROR:
    'There was an error while sharing the profile. Please try again later',
  PROFILE_LOGIN_SHARE_MESSAGE: 'Log in to share Heat Profile.',
  TRY_AGAIN_LATER: 'Please try again later.',
  NETWORK_ERROR: 'Network Error',
  FEATURE_ACCESS_MESSAGE: 'Sign in to access this feature of the app',
  FEATURE_ACCESS_MESSAGE_2:
    'Sign in or create an account to access this feature',
  VERIFY_TITLE: 'Account Not Verified',
  VERIFY_ACCESS_MESSAGE: 'Verify your email to access this feature of the app',
  ACCESS_DENIED: 'Access Denied',
  SHARED_DAB_NOT_CONNECTED_TITLE: 'Not Connected',
  SHARED_DAB_NOT_CONNECTED_BODY:
    'Your device must be connected and within range to use this feature',
  DABBING_ERROR_TITLE: 'Dab Error',
  DABBING_ERROR_BODY: 'Something went wrong. Please try again.',
  NOT_CONNECTED: 'Device not connected',
  RESET_DETAILS_FAILED: 'Reset details failed',
  RESET_PASSWORD_FAILED: 'Reset password failed.',
  SHARE_PROFILE_READY_TITLE: 'Share {profileName}',
  SHARE_PROFILE_READY_BODY: '{profileName} is ready to share!',
  START_UPDATE_FIRMWARE_MESSAGE: 'Start Update',
  UPDATE_FIRMWARE_LATER_MESSAGE: 'Maybe Later',
  UPDATE_FIRMWARE: 'Update Firmware',
  UPDATE_FIRMWARE_BODY: 'Update firmware to use this feature.',
  UPDATE_FIRMWARE_MESSAGE: 'Update your firmware to access this feature',
  XL_CHAMBER_UPDATE_FIRMWARE_MESSAGE:
    'Please update your firmware to use the XL chamber.',
  CHAMBER_DISCONNECTED_TITLE: 'Chamber Disconnected',
  CHAMBER_DISCONNECTED_MESSAGE:
    'The chamber is disconnected or cannot be recognized. Make sure you inserted the chamber correctly and you have the latest firmware installed.',
  MOOD_LIGHT_UPDATES_REQUIRED: 'Updates Required to Access Mood Lights',
  MOOD_LIGHT_GATING_MESSAGE:
    "Please ensure you are logged in and your Peak's firmware is up to date.",
  NEW_USERNAME_TITLE: 'New Username',
  NEW_USERNAME_BODY:
    'We assigned you a default username ({username}) that you can change now or at any time by accessing the settings menu.',
  USERNAME_UPDATED_MESSAGE: 'Your account username has been updated.',
  LOGIN_SUCCESS_MESSAGE: 'Your account has been created! You can now sign in.',
  REGISTER_SUCCESS_MESSAGE: 'Your account has been created!',
  SCAN_ERROR_TITLE: 'Scan Error',
  CONNECT_DEVICE_FAILED_TITLE: 'Connect Device Error',
  CONNECT_DEVICE_FAILED_BODY:
    'Something went wrong while connecting to the device. Please try again.',
  REQUEST_EMAIL_VERIFICATION: 'Confirm Email',
  REQUEST_EMAIL_VERIFICATION_SUCCESS:
    'We emailed a verification link to: {email} \nCheck your inbox and click the link to confirm your Puffco account.',
  REQUEST_EMAIL_VERIFICATION_ERROR: 'Request Email Verification Error',
  CONFIRM_DELETE_DEVICE_TITLE: 'Delete Device',
  CONFIRM_DELETE_DEVICE_BODY:
    'This action will remove the device from the app memory.',
  INCOMPATIBLE_ANIMATION_TITLE: 'Incompatible Animation',
  INCOMPATIBLE_ANIMATION_BODY:
    'This mood light features an animation that can only be viewed on the new Peak Pro.',
  NEEDS_UPDATE_TITLE: 'Puffco Connect Update Available',
  NEEDS_UPDATE_BODY: 'Please update your app to access the latest features.',
  NEEDS_REFRESH_TITLE: 'Refresh Required',
  NEEDS_REFRESH_BODY:
    'We have a new version available. Please refresh the page',
  SELECT_PEAK_TITLE: 'Select device',
  SELECT_PEAK_BODY: 'Select your Peak Pro device to connect',
  permissions: {
    android: {
      location: {
        disabled: {
          title: 'Location Required',
          body: 'Enable GPS Location services to connect with Peak device.',
        },
        denied: {
          title: 'Location Required',
          body: 'Enable Location permission to connect to your peak device. Go to Android Settings > Apps > Puffco > Permissions > Location.',
        },
        coarse: {
          title: 'Location Required',
          body: 'Precise Location is needed to connect to your peak device. Go to Android Settings > Apps > Puffco > Permissions > Location and toggle "Use precise location".',
        },
      },
      bluetooth: {
        denied: {
          title: 'Bluetooth Required',
          body: 'Enable Bluetooth/Nearby Devices permission in the device settings to connect with Peak device.',
        },
      },
    },
    ios: {
      bluetooth: {
        denied: {
          title: '"Path Browser" requires access to Bluetooth',
          body: 'You can enable Bluetooth in your phone settings.',
        },
      },
    },
  },
};

export const Messages = {
  OTA: {
    PENDING: {
      title: 'Firmware Update Pending',
      body: `The firmware update was aborted. You can use your Peak once the firmware update completed.`,
    },
    DOWNLOADING: {
      title: 'Downloading Firmware',
      body: 'Downloading firmware from the file server.',
    },
    UPDATED: {
      title: 'Update Complete',
      body: 'You may continue using your Peak now.',
    },
    UPDATING: {
      title: 'Update In Progress',
      body: 'Your Peak is being updated with new firmware. Do not turn off or disconnect your Peak or mobile device.',
    },
  },
  VAPOR: {
    [Vapor.Standard]: 'STANDARD',
    [Vapor.High]: 'HIGH',
    [Vapor.Max]: 'MAX',
    [Vapor.Xl]: 'XL',
  },
  BATTERY: {
    MIN_UNTIL_CHARGED: `{min} min until fully charged`,
    MIN_REMAINING: '{min} min remaining',
    MIN_MAX_DABS_REMAINING: `{minDabs}-{maxDabs} dabs remaining`,
  },
  NEW_EXCLUSIVE_MOODLIGHT: 'New exclusive mood light',
  CHECK_IT_OUT: 'Check it out',
  SNOOZE_FREQUENCY_SCREEN_TITLE: 'SNOOZE FREQUENCY',
  SNOOZE_FREQUENCY_HEADER: 'Select Number of Dabs',
  SNOOZE_SECTION_HEADER_TITLE: 'Snooze',
  REMINDER_SECTION_HEADER_TITLE: 'My Reminder',
  RESET_REMINDER_TITLE: 'Reset Reminder',
  CLEANING_REMINDER_HEADER_TITLE: 'Reminder',
  CLEANING_REMINDER_NAVIGATION_TITLE: 'Cleaning Reminders',
  CLEANING_REMINDER_OFF_TEXT: 'You have reminders turned off',
  CLEANING_INSTRUCTIONS_HEADER_TITLE: 'CLEANING INSTRUCTIONS',
  DEVICE_SECTION_HEADER_TITLE: 'Device',
  DOWNLOAD_CLEANING_PDF: 'Download PDF',
  MODEL_SECTION_TITLE: 'Model',
  DEVICE_NAME_SECTION_TITLE: 'Device Name',
  FIRMWARE_SECTION_TITLE: 'Firmware',
  SERIAL_NUMBER_SECTION_TITLE: 'Serial Number',
  DOB_SECTION_TITLE: 'DOB',
  SESSION_COUNT_SECTION_TITLE: 'Session Count',
  BATTERY_PRESERVATION_SECTION_TITLE: 'Battery Preservation',
  BATTERY_PRESERVATION_SUBTITLE: 'Your battery will only charge to 80%',
  APPLICATION_SECTION_HEADER_TITLE: 'APPLICATION',
  PUSH_NOTIFICATIONS_SECTION_TITLE: 'Push Notifications',
  TEMPERATURE_SECTION_TITLE: 'Temperature',
  PRIVACY_SECTION_TITLE: 'Privacy Policy',
  TOS_SECTION_TITLE: 'Terms & Conditions',
  VERSION_SECTION_TITLE: 'Version',
  ENABLE_PEAK_ANIMATION_SECTION_TITLE: 'Enable Peak Animation',
  FACTORY_RESET_TITLE: 'Factory Reset',

  EXISTING_DAB_VALUE: 'Dab already exists',
  CUSTOM_DAB_DESCRIPTION:
    'Input the number of dabs when you want a reminder to be sent',
  CUSTOM_DAB_TITLE: 'Customize Frequency',
  NUMBER_DABS: 'Select Number of Dabs',

  LOGIN_TITLE_REDIRECTED:
    'Please log-in or create a new account to access that feature',
  LOGIN_TITLE: 'Sign in',
  LOGIN_NO_ACCOUNT: "Don't have an account?",
  LOGIN_CREATE_ONE: 'CREATE ONE',
  MARKET_OPT_IN_CONFIRMED_TITLE: 'Congratulations!',
  MARKET_OPT_IN_CONFIRMED_DESC:
    'You now have early access to Puffco product launches. A verification link has been sent to:',
  MARKET_OPT_IN_CONFIRMED_DESC_SMS:
    'You now have early access to Puffco product launches. We sent your verification link to:',
  FORGOT_PASSWORD_TITLE: 'FORGOT PASSWORD',
  RESET_PASSWORD_TITLE: 'RESET PASSWORD',
  RESET_PASSWORD_PROMPT:
    'Don’t worry, it happens to the best of us. Please enter the email address you used to create your Puffco account.',
  CREATE_ACCOUNT_PROMPT: 'CREATE AN ACCOUNT',
  CREATE_ACCOUNT_TWO: 'CREATE ACCOUNT',
  CONTINUE_WITH_APPLE: 'CONTINUE WITH APPLE',
  CONTINUE_WITH_GOOGLE: 'CONTINUE WITH GOOGLE',
  MARKET_OPT_IN_PROMP: 'I want to receive promotional emails from Puffco',
  MARKET_OPT_IN_SIGN_IN_PROMPT: 'Sign up for access',
  EMAIL_NOT_VERIFIED_PROMPT: 'Slow down bucko',
  EMAIL_NOT_VERIFIED_DESC:
    "You'll need to confirm your email address to access this feature",
  DEVICE_SETTINGS_PERSONALIZE_LIGHTS:
    'Personalize the lights of your Peak to enhance your dabbing experience.',
  DEVICE_SETTINGS_BOOST: `Boost Mode gives you the choice to add time, temperature, or both to your dabbing session.\n\nActivate Boost Mode by double-clicking your peak button while dabbing.`,
  DEVICE_SETTINGS_READY_MODE:
    'Select a heat setting to auto-initiate when removed from The Puffco Induction Charging Pad (required).\n\nClick the peak button once to cancel the heat cycle.\n\nRestarting the Peak will Reset Ready Mode.',
  DEVICE_SETTINGS_READY_NOTE:
    "Note: By activating Ready Mode, you are accepting Puffco's Terms & Conditions",
  DEVICE_SETTINGS_LANTERN_MODE:
    'Set the mood by choosing a color and animation for your Peak Pro to perform.',
  DEVICE_SETTINGS_LANTERN_WARNING:
    'WARNING: Lights may drain your battery if left on for an extended period of time.',
  EPILEPSY_WARNING_TITLE: 'EPILEPSY WARNING',
  EPILEPSY_WARNING_DESCRIPTION:
    'This feature may potentially trigger seizures for people with photosensitive epilepsy. User discretion is advised.',
  HOME_PAIR_FIRST: 'CLICK HERE TO PAIR YOUR FIRST DEVICE',

  WARRANTY_SUCCESS_BODY:
    "We've received your warranty claim form and we'll get back to you within 5 to 7 days if we need any follow up.",
  WARRANTY_SUCCESS_EMAIL: 'support@puffco.com',
  SAVE_TO_DEVICE_TEXT:
    'Select the active heat profile you would like to replace on your Puffco Peak Pro.',
  SUPPORT_EXCHANGES:
    'For exchanges, or shipping issues please email support@puffco.com.',
  SUPPORT_IMPERFECTION:
    'If your experience is anything less than perfection, please fill out the warranty claim above.',
  HEAT_PROFILE_NOTICE:
    'You must be logged in to access the archive features (saving Peak default profiles, creating new profiles, rearrange list order)',

  FACTORY_RESET_CONFIRMATION:
    "Please confirm you would like to reset your device. \n\nOnce the reset is complete you must go into your phone's bluetooth settings and forget the Peak. Then click and hold the power button until the logo glows blue.",
  FACTORY_RESET_DEVICE_DISCONNECTED:
    'Please connect to your device to perform a factory reset.',
  FACTORY_RESET_NO_CHARACTERISTIC:
    "Your device's firmware doesn't support performing a factory reset.",
  UNABLE_TO_CONNECT_TO_SERVER: 'Unable to connect to server.',
  UNABLE_TO_CONNECT_TO_SERVER2: 'Unable to connect to server..',
  MOOD_LIGHT_INSTRUCTIONS:
    'Select a mood to be displayed on your peak device or create your own custom mood.',
  WELCOME_TITLE: 'WELCOME TO THE PUFFCO FAMILY!',
  WELCOME_SUBTITLE: `IT'S GREAT TO MEET YOU`,
  SMS_AGE_REQUIREMENT: 'Must be 21 or older',
  SMS_OPT_IN_HEADER: 'Get early access to product launches',
  SMS_OPT_IN_CHECKBOX_TEXT: 'I want to receive marketing messages from Puffco',
  LEGAL_INTRO_TITLE: 'Your trust is important',
  LEGAL_INTRO_MESSAGE:
    'We’ll never track your location without your permission. Your data is only collected after opting in and connecting a Puffco device to the Puffco Connect app. If you’d like to stop sharing your data, you can opt out at any time in the app settings.',
  LEGAL_INTRO_AGREEMENT:
    'By clicking “Accept” I agree that I have read and accepted the Terms & Conditions and Privacy Policy',
  CLEANING_REMINDER_TITLE: 'CLEANING REMINDER',
  CLEANING_REMINDER_MESSAGE: 'TIME TO CLEAN YOUR PEAK PRO',
  CLEANING_REMINDER_RESET_BTN: 'RESET REMINDER',
  CLEANING_REMINDER_SNOOZE_BTN: 'SNOOZE',
  MARKET_OPT_IN_HEADER_TITLE: 'Want early access to product launches?',
  MARKET_OPT_IN_NAVIGATION_TITLE: 'Definitely',
  MARKET_OPT_IN_DISMISS_TITLE: 'Not Today',
  MARKET_OPT_IN_AGREE_EMAIL:
    'By entering your email address, you agree to receive our marketing offers in accordance with our Privacy Policy.',
  MARKET_OPT_IN_AGREE_PHONE:
    'By entering your phone number, you agree to receive info on product launches in accordance with our Privacy Policy.',
  MARKET_OPT_IN_AGREE:
    'By joining via e-mail or text messages, you agree to receive recurring marketing messages (e.g. cart abandon) from Puffco at the mobile number used to send the join text message. Msq & data rates may apply. Msq frequency varies. Consent is not a condition of any purchase. STOP to unsubscribe, HELP for help.',
  // ConnectScreen
  SEARCHING_FOR_DEVICE_TITLE: 'Searching...',
  CONNECTION_FOUND_TITLE: 'Peak Found',
  CONNECTION_FOUND_MSG:
    'Please wait while we initialize your Peak for pairing.',
  CONNECTION_SUCCESS_DONE_MSG: 'Your peak is now connected and ready to use.',
  NAME_YOUR_PEAK_TITLE: 'Name your Peak',
  NAME_YOUR_PEAK_DONE_MSG:
    'Give your Peak a name. You can always change it later.',
  NO_ACCOUNT: "Don't have an account?",
  ACCOUNT_CREATED_TITLE: 'Account Created',
  ACCOUNT_CREATED_SOCIAL: 'A Puffco account has been created for',
  ACCOUNT_CREATED_SIGNUP: 'We emailed your verification link(s) to:',
  UPDATE_USERNAME_TITLE: 'UPDATE YOUR USERNAME',
  UPDATE_USERNAME_MSG:
    'We’ve given you a default username that you can change now or at any time by accessing the settings menu',
  START_PAIRING_TITLE: 'Pairing your Peak Pro',
  START_PAIRING_MSG:
    '{appName} requires Bluetooth to connect to your Peak Pro. Make sure your device is charged and nearby before continuing.',
  PAIRING_INSTRUCTIONS: {
    pairing: {
      title: 'Pair your Peak',
      body: 'Click and hold the power button until the logo glows blue.',
    },
    location: {
      title: 'Allow Location',
      body: 'Puffco Connect uses location to find your Peak. You can adjust your location settings any time.',
    },
    bluetooth: {
      title: 'Allow Bluetooth',
      body: 'Puffco Connect uses Bluetooth to connect to your Peak. You can adjust your Bluetooth settings any time.',
    },
  },
  DAB_HISTORY_TITLE: 'DAB HISTORY',
  DAB_AVG_TITLE: 'Avg',
  AVG_DURATION_TITLE: 'AVG DURATION',
  POPULAR_TEMPERATURE: 'POPULAR TEMP',
  FULLSCREEN: 'Fullscreen Mode',
  CAROUSEL_MOOD_LIGHT_TITLE: 'Custom Mood Lights',
  CAROUSEL_MOOD_LIGHT_BODY:
    'Illuminate your Peak Pro experience with a feature that provides limitless potential to customize your Peak Pro LED lighting.',
  CAROUSEL_HEAT_PROFILES_TITLE: 'Custom Heat Profiles',
  CAROUSEL_HEAT_PROFILES_BODY:
    'The app comes with 4 standard Heat Profiles, but provides the ability to create and save dozens of customized profiles depending on your needs.',
  CAROUSEL_ADVANCED_DAB_TITLE: 'Advanced Dab Metrics',
  CAROUSEL_ADVACED_DAB_BODY:
    'Advanced Metrics allows you to keep a close eye on your dabbing habits. You can view your dabs over time and see the breakdown by device.',
  REQUEST_RESET_PASSWORD_BTN: 'Request Password Reset',
  PLEASE_WAIT_MSG: 'Please wait',
  RESET_PASSWORD_BTN: 'Reset Password',
  PASSWORD_CONFIRMED_TITLE: 'Password Confirmed',
  PASSWORD_CONFIRMED_MSG:
    'Your password has successfully been reset. Please use this password the next time you log into the Puffco app.',
  PASSWORD_RESET_SENT_TITLE: 'Password Reset',
  PASSWORD_RESET_SENT_MSG: 'We emailed a password reset link to:',
  PASSWORD_RESET_INSTRUCTIONS:
    ' Check your inbox and click the link to change your Puffco password',

  CREATE_ACCOUNT_BTN: 'CREATE ACCOUNT',
  CAROUSEL_DESCRIPTION: 'Unlock more with an account',
  DYNAMIC_INHALE_TITLE: 'Dynamic Inhale',
  DYNAMIC_INHALE_MSG:
    'Assign this Mood Light to a Heat Profile and the animation will change tempo as you inhale',
  MOOD_LIGHT_EDITOR_TITLE: 'MOOD LIGHT EDITOR',
  MOOD_LIGHTS_LIBARY_TITLE: 'MOOD LIGHTS',
  FIRST_VIEW_ADVANCED_METRIC_TITLE: 'Welcome to Advanced Metrics',
  FIRST_VIEW_ADVANCED_METRIC_BODY:
    'Sync your device regularly (about every 100 dabs) for the most accurate data. Data that is not synced may be lost and will not appear in your Dashboard.',
  ADVANCED_METRICS_EMPTY_TITLE: 'Data Unavailable',
  ADVANCED_METRICS_EMPTY_MSG:
    'Start dabbing to get insights into your dab history, popular dab temps, average dab length, and your most used Mood Light.',

  HEAT_PROFILE_EDIT_MOOD_LIGHT_BTN: 'Mood Lights',
  HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_CHAMBER_AND_FIRMWARE:
    'Get a 3D Chamber and update your firmware to unlock this feature',
  HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_CHAMBER:
    'Get a 3D Chamber to unlock this feature',
  HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_FIRMWARE:
    'Upgrade your firmware to unlock this feature',
  NO_MOOD_LIGHT_DATA: 'NO MOOD LIGHT DATA',
  USE_MOODLIGHTS: 'Use Mood Lights',
  METRICS_SCREEN_ALERT_TITLE: 'INTRODUCING ADVANCED METRICS',
  METRICS_SCREEN_ALERT_MSG:
    'Get insights into your sessions like never before and easily share your stats. Choose a time scale and see your dab data come to life including number of dabs with duration and daily averages.',

  UPGRADE_3DXL_CHAMBER_TITLE: 'THE BEST JUST GOT BIGGER.',
  UPGRADE_3DXL_CHAMBER_BODY:
    'Introducing the world’s first high capacity e-rig chamber: the new Peak Pro 3DXL. Bigger dabs. Bigger clouds. Rip harder with less reclaim. An exclusive new Vapor Control setting. XL Joystick Cap.',
  XL_CHAMBER_UPSELL_TITLE: 'Looking for more Vapor?',
  XL_CHAMBER_UPSELL_BODY:
    'Upgrade to a XL chamber to increase vapor production by 100%.',
  XL_CHAMBER_FOOTER:
    '*Compared to the Puffco 3D chamber. Battery life will be reduced.',
  XL_UNLOCKED_ALERT_SCREEN_TITLE: 'XL CHAMBER UNLOCKED',
  XL_UNLOCKED_ALERT_SCREEN_MSG: `Welcome to the 3DXL experience. Don’t forget the “XL” Vapor Control setting, exclusive to 3DXL owners.`,
  V3_CHAMBER_SCREEN_TITLE: 'EXPLORE 3D TECHNOLOGY',
  V3_CHAMBER_SCREEN_MSG:
    'Our patented 3D Chambers give you bigger clouds, better flavor, and a consistent hit every time. Every 3D Chamber unlocks vapor control settings in the app to maximize your experience.',
  REWARDS: {
    YOU_EARNED_AMOUNT: 'You earned {amount}!',
    NO_REWARDS_EARNED: 'No rewards earned',
    START_REFERRING: 'Start referring to earn Puffco rewards',
    LOGIN: 'Login to start referring',
    CREAT_ACCOUNT:
      'If you have an account on the Puffco website, please create an account here using the same email you registered with. All of your referrals will be linked to this email.',
    ENJOY_YOUR_REWARD:
      'Enjoy your {amount} reward*, and thank you for helping us grow the Puffco community. You can view your discount codes in the “Refer a Friend” section of the app.',
    YOUR_DISCOUNT_CODE: 'Your {amount} discount code:',
    DISCOUNTS_CANNOT_STACK:
      '*Discount codes do not stack. One code per order. Enter code at checkout',
    REFER_REWARD_REPEAT: 'Refer. Reward. Repeat.',
    USE_MY_LINK:
      'Use my link below for {amount} off your first Puffco Proxy, Peak Pro, Peak, or Plus. Hash is better with friends. Shop here:',
    GET_AMOUNT: `Get {amount} for each friend you refer – they'll get {amount} off their first Puffco purchase.*`,
    FOOTER_NOTE1:
      '*{amount} off valid for first-time Puffco customers only, {cartMinimum} cart minimum. Cart must include a Proxy, Peak Pro, Peak, or Plus. Codes do not expire. Codes cannot stack.',
    FOOTER_NOTE2:
      '*For referrers, {amount} off valid for any Puffco product, no cart minimum. This offer does not apply to new products within the first 30 days of their release. Discount codes cannot stack.',
  },
  HAVE_A_QUESTION: 'Have a question?',
};

export const DabbingTips = ['SLOW INHALES PRODUCE MORE VAPOR'];
export const DabbingReminders = ['THIS PROFILE IS NOT SAVED TO YOUR DEVICE'];
export const XLDabbingTips = [
  'HARDER HITS CAN PRODUCE MORE VAPOR ON XL CHAMBERS',
];
export const NonPathShareReminder = 'Please follow instructions carefully';

export const Strings = {
  ACCEPT: 'Accept',
  ACTIVE: 'ACTIVE',
  ADVANCED_METRICS: 'Advanced Metrics',
  ADDITIONAL_INFORMATION: 'Additional Information',
  APPLE_SIGN_IN: 'SIGN IN WITH APPLE',
  ALLOW: 'Allow',
  ARCHIVE: 'ARCHIVE',
  AGREE: 'I Agree',
  BACK_TO_TOP: 'Back to Top',
  CLOSE: 'Close',
  CANCEL: 'Cancel',
  CHAMBER_DISCONNECTED: 'CHAMBER DISCONNECTED',
  CHARGING: 'CHARGING',
  CHARGE_COMPLETE: 'CHARGE COMPLETE',
  CHARGING_TO_EIGHTY: 'CHARGING TO 80%',
  CLEANING_MANUAL: 'CLEANING MANUAL',
  CHECK_IT_OUT: 'Check it out',
  CONTINUE: 'Continue',
  CONNECTED: 'Connected',
  CONNECTING: 'Connecting...',
  COPIED: 'Copied',
  COPY: 'Copy',
  CREATE_ACCOUNT: 'Create Account',
  USER_MANUAL: 'User manual',
  PREPARING_MOOD_LIGHT_SHARE: 'Preparing Mood Light for Share',
  PREPARING_PROFILE_SHARE: 'Preparing Profile for Share',
  PREPARING_ADVANCED_METRICS: 'Preparing Advance Metrics for Share',
  CUSTOM: 'Custom',
  DABS: 'Dabs',
  DAYS: 'Days',
  COLOR: 'COLOR',
  MOOD: 'MOOD',
  DECLINE: 'Decline',
  DECLINE_AND_CLOSE_APP: 'Decline & close app',
  DELETING: 'DELETING',
  DEVICE: 'DEVICE',
  DEVICES: 'DEVICES',
  DELETE_DEVICE: 'Delete Device',
  DISCONNECTED: 'DISCONNECTED',
  DISCONNECTING: 'Disconnecting',
  DISMISS: 'Dismiss',
  DONE: 'DONE',
  DONT_ALLOW: `Don't Allow`,
  NEXT: 'NEXT',
  NEXT_ENTRY: 'Next Entry',
  DEFAULT_BTN: 'Got it',
  DOWNLOAD: 'Download',
  DOWNLOAD_APP: 'DOWNLOAD APP',
  DOWNLOADING_SHARED_MOOD: 'DOWNLOADING SHARED MOOD',
  DOWNLOADING_SHARED_PROFILE: 'DOWNLOADING SHARED PROFILE',
  ERROR: 'ERROR',
  EMAIL: 'Email',
  EXCLUSIVE: 'EXCLUSIVE',
  EXCLUSIVE_DISCLAIMER: 'Exclusive Mood Lights cannot be customized.',
  FULLY_CHARGED: 'FULLY CHARGED',
  GET_STARTED: 'GET STARTED',
  GOOGLE_SIGN_IN: 'SIGN IN WITH GOOGLE',
  HOURS: 'Hours',
  I_UNDERSTAND: 'I UNDERSTAND',
  INSUFFICIENT_BATTERY: 'INSUFFICIENT BATTERY',
  INITIALIZING: 'Initializing',
  LOG_IN: 'Log In',
  LOGIN: 'Login',
  MAYBE_LATER: 'MAYBE LATER',
  MINUTES: 'Minutes',
  MOBILE_ONLY: 'MOBILE ONLY',
  MOOD_LIGHTING: 'MOOD LIGHTING',
  MOOD_LIGHTS: 'MOOD LIGHTS',
  MY_COLLECTIONS: 'MY COLLECTION',
  NO_REMINDER: 'No Reminder',
  NOT_NOW: 'Not Now',
  PAIRING: 'Pairing',
  PROCEED: 'Proceed',
  READY_TO_RIP: 'READY TO RIP',
  START_DABBING: 'Start Dabbing',
  SAVE: 'Save',
  SAVING: 'SAVING',
  SEARCHING: 'Searching',
  SECONDS: 'Seconds',
  SETTINGS: 'Settings',
  SHARE: 'Share',
  SHARE_THIS_SCREEN: 'Share this screen',
  SHARED_MOOD: 'SHARED MOOD',
  SHOP_NOW: 'SHOP NOW',
  SIGN_ME_UP: 'SIGN ME UP',
  SIGN_IN: 'Sign in',
  SIGN_UP: 'Sign up',
  SNOOZE: 'Snooze',
  START_PAIRING: 'START PAIRING',
  SKIP: 'Skip',
  TEMPO: 'TEMPO',
  TRY_AGAIN: 'TRY AGAIN',
  UPDATE: 'Update',
  WARNING: 'WARNING',
  ACCOUNT_LOCKED: 'ACCOUNT TEMPORARILY LOCKED',
  POPULAR_MOOD_LIGHTS: 'Popular Mood Lights',
  DEVICE_OUT_OF_RANGE: 'Device out of range',
  PAIRING_DEVICE: 'Pairing device...',
  SEARCHING_DEVICE: 'Searching...',
  CONNECT_DEVICE_FOR_INFO: 'Connect device for info',
  LOADING_DATA: 'Loading...',
  NOT_BROADCASTING: 'NOT BROADCASTING',
  UPDATE_USERNAME: 'UPDATE USERNAME',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  CONTROL_PANEL: 'Control Panel',
  COMPLEXITY: 'COMPLEXITY',
  RANDOMNESS: 'RANDOMNESS',
  ANIMATION: 'Animation',
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
  ANIMATIONS: 'ANIMATIONS',
  UPDATE_FIRMWARE: 'UPDATE FIRMWARE',
  OKAY: 'Okay',
  PATH_BROWSER: 'Path Browser',
  PLACE: 'Place',
  PREVIOUS: 'Previous',
  PREVIOUS_ENTRY: 'Previous Entry',
  PUFFCO_CONNECT: 'Puffco Connect',
  REFRESH: 'Refresh',
  OPEN: 'Open',
  STEP: 'Step',
  HELP: 'Help',
  NO_RESULTS: 'No results found',
  NO_RESULTS_TRY_AGAIN: 'Try again using more general words',
  START_SHOPPING: 'Start shopping',
  REFERRAL_PROGRAM: {
    REFER_A_FRIEND: 'Refer a Friend',
    MY_REWARDS: 'My rewards',
    NO_REWARDS: 'No rewards',
    EARN_30: 'Earn {amount}',
    SHARE_REFERRAL: 'Share referral',
  },
  REHASH: {
    PUFFCO_REHASH: 'Puffco Rehash',
    SHARE_TITLE: 'Share Rehash {year}',
    SHARE_MESSAGE: 'You are about to share your Rehash {year}',
  },
  DEVICE_EDUCATION: {
    SUPPORT_SCREEN: {
      TITLE: 'Device Support',
      CHOOSE_DEVICE: 'Choose a device',
      WALKTHROUGHS: 'Walkthroughs',
      FAQ: 'Faq',
      FOOTER: 'Still have a question?',
      INSTRUCTIONS_MANUAL: 'View instruction manual',
      VIDEOS: 'Videos',
      CONTACT_SUPPORT: 'Contact support',
    },
    GUIDESCREEN: {
      VIEW_GUIDE: 'View guide',
      DEFAULT_TITLE: 'Quick start guide',
    },
    FAQ: {
      TITLE: 'FAQ',
      TAGS: 'Filter questions by selecting tags',
      POPULAR_TOPICS: 'Popular topics',
    },
    DEVICE_DOCUMENTS: {
      FOOTER: 'Still having issues?',
      DEFAULT_TITLE: 'Device document',
    },
  },
  COMMUNITY: {
    TITLE: 'Community',
  },
  SLIDER: {
    TEMPERATURE: 'TEMPERATURE',
    DURATION: 'DURATION',
    VAPOR: 'VAPOR CONTROL',
  },
};

// Not currently used
// export const HeatProfileCardShader = {
//   IOS: GLSL`
//   precision highp float;
//   uniform vec3 u_color;
//   uniform float u_radius;
//   uniform vec2 u_resolution;
//   uniform float u_pixelratio;

//   float expStep( float x, float k, float n ){
//     return exp( -k*pow(x,n) ) * 2. ;
//   }

//   void main() {
//     float thickness = 1.0 * u_pixelratio;            //white ring thickness
//     vec2 center = u_resolution / 2.0;

//     vec3 background = vec3(0.0, 0.0, 0.0);
//     vec3 white = vec3(1.0);

//     float d = distance(gl_FragCoord.xy, center);
//     vec3 fragColor = u_color;

//     //Draw ring using an exponential step
//     float d2 = abs(u_radius - d);
//     if (d < u_radius) {
//         fragColor = u_color * expStep(d2, .1, 1.0);     //inner glow
//     } else {
//         fragColor = u_color * expStep(d2, .05, 0.94);      //outer glow
//     }

//     //Draw ring with overexposure
//     if (d < u_radius-thickness) {
//         // keep existing fragColor
//     } else if (d >= u_radius-thickness && d < u_radius) {
//         fragColor = mix(fragColor, white, smoothstep(u_radius-thickness, u_radius, d));
//     } else if (d >= u_radius) {
//         fragColor = mix(white, fragColor, smoothstep(u_radius, u_radius+thickness, d));
//     }
//     gl_FragColor = vec4(fragColor, 0.0);
//   }
//   `,
//   ANDROID: GLSL`
//     precision highp float;
//     uniform vec3 u_color;
//     uniform float u_radius;
//     uniform vec2 u_resolution;
//     uniform float u_pixelratio;

//     float expStep( float x, float k, float n ){
//       return exp( -k*pow(x,n) ) * 2. ;
//     }

//     void main() {
//       float thickness = 1.0 * u_pixelratio;            //white ring thickness
//       vec2 center = u_resolution / 2.0;

//       vec3 background = vec3(0.0, 0.0, 0.0);
//       vec3 white = vec3(1.0);

//       float d = distance(gl_FragCoord.xy, center);
//       vec3 fragColor = u_color;

//       //Draw ring using an exponential step
//       float d2 = abs(u_radius - d);
//       if (d < u_radius) {
//           fragColor = u_color * expStep(d2, .1, 1.0);     //inner glow
//       } else {
//           fragColor = u_color * expStep(d2, .05, 0.94);      //outer glow
//       }

//       //Draw ring with overexposure
//       if (d < u_radius-thickness) {
//           // keep existing fragColor
//       } else if (d >= u_radius-thickness && d < u_radius) {
//           fragColor = mix(fragColor, white, smoothstep(u_radius-thickness, u_radius, d));
//       } else if (d >= u_radius) {
//           fragColor = mix(white, fragColor, smoothstep(u_radius, u_radius+thickness, d));
//       }
//       gl_FragColor = vec4(fragColor, 0.0);
//     }
//     `,
// };
