import {useIsFocused, useNavigation} from '@react-navigation/native';
import React from 'react';
import {SectionList, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useAsyncFn, useStateWithHistory} from 'react-use';
import {ulid} from 'ulid';

import {
  Alerts,
  Constants,
  Navigators,
  RECORDS,
  Screens,
  Strings,
  appColors,
  centerStyle,
  interpolate,
} from '../constants';
import {
  saveMoodlight,
  useDeleteMoodLight,
  useGetAndStoreExclusiveMoodLights,
  useGetAndUpdateUsernameCache,
  useGetMoodLights,
  useSaveMoodLight,
} from '../lib/api/MoodLight.hooks';
import {shareApi} from '../lib/api/apis';
import {
  useAppDispatch,
  useDebouncedWriteLanternMoodLight,
  useGetUpdatedPeakMoodLights,
  useHasLed3Api,
  useUserVerifiedGuard,
} from '../lib/hooks';
import {findMatchingMoodLight} from '../lib/moodLightFunctions';
import {
  connectedPeakSelector,
  currentDeviceSelector,
  updateDeviceSettings,
} from '../lib/redux/bleSlice';
import {
  accountMoodLightsSelector,
  exclusiveMoodLightsSelector,
  getAccountMoodLightSelector,
  getMoodLightSelector,
  lanternMoodLightSelector,
  peakMoodLightsSelector,
  setLanternMoodLightId,
  setPeakMoodLights,
  upsertAccountMoodLight,
  upsertAccountMoodLights,
} from '../lib/redux/moodLightSlice';
import {userSelector} from '../lib/redux/userSlice';
import styled from '../lib/styled';
import {
  CustomMoodLight,
  MoodLight,
  isCustomMoodLight,
  isExclusiveMoodLight,
} from '../lib/types';
import {useTheme} from '../lib/useTheme';
import {verifyGuard} from '../lib/userFunctions';
import {
  elapsedSecondsSince,
  isAdvancedMoodLight,
} from '../lib/utilityFunctions';
import {shareUrl} from '../lib/utilityFunctions/shareUrl';
import {
  RootStackParamList,
  RootStackScreenProps,
} from '../navigation/navigators/RootStackNavigator';
import {
  toHeatProfileEdit,
  toMoodLightLibrary,
} from '../navigation/navigators/util';
import {Alert} from '../shims/alert';
import {analytics} from '../src/services/analytics';
import {getAppUrl} from '../src/util/url';
import {BottomSheetComponent} from './BottomSheet';
import {
  BottomSheetButtonProps,
  BottomSheetButtonTitle,
} from './BottomSheetButton';
import {BottomSheetDetail} from './BottomSheetDetail';
import {MoodLightCard} from './MoodLightCard';
import {MoodLightIcon} from './MoodLightIcon';
import {MultiGradientBackground} from './MultiGradientBackground';
import {SectionTitle} from './SectionTitle';
import {useSpinner} from './Spinner';

const {MOOD_TYPE_NAME_RECORD} = RECORDS;

export type MoodLightListProps = {
  currentActiveId?: string;
  setActiveMoodLightId?: (id: string) => void;
  shouldShowPeakMoodLights?: boolean;
};

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

// TODO: This is used in more than one screens
export const MoodLightList = ({
  currentActiveId,
  setActiveMoodLightId,
  shouldShowPeakMoodLights = true,
}: MoodLightListProps) => {
  const theme = useTheme();
  const {
    bottomSheetTheme: {gradientShadeFunction: iconGradientFunction},
  } = theme;
  const dispatch = useAppDispatch();
  const {navigate} = useNavigation<Navigation>();
  const peak = useSelector(connectedPeakSelector);
  const currentDevice = useSelector(currentDeviceSelector);
  const peakMoodLights = useSelector(peakMoodLightsSelector);
  const accountMoodLights = useSelector(accountMoodLightsSelector);
  const exclusiveMoodLights = useSelector(exclusiveMoodLightsSelector);
  const lanternMoodLight = useSelector(lanternMoodLightSelector);
  const getMoodLight = useSelector(getMoodLightSelector);
  const getAccountMoodLight = useSelector(getAccountMoodLightSelector);
  const user = useSelector(userSelector);
  const isFocused = useIsFocused();
  const hasLed3Api = useHasLed3Api();
  const [{value: moodLights, error: getError}, get] = useGetMoodLights();
  const [{loading: isDownloading}, download] = useSaveMoodLight({
    onSuccess: onSuccessDownload,
    onError: onSaveError,
  });
  const [{loading: isDuplicating}, duplicate] = useSaveMoodLight({
    onSuccess: onSuccessDuplicate,
    onError: onSaveError,
  });
  const [{loading: isDeleting}, deleteMoodLight] = useDeleteMoodLight();

  useGetAndStoreExclusiveMoodLights(exclusiveMoodLights.length === 0);

  const shareMoodLightById = async ({
    shareId,
    moodlightId,
    moodlightName,
  }: {
    shareId: string;
    moodlightId: string;
    moodlightName: string;
  }) => {
    const url = `${getAppUrl()}/${Constants.SHARE_PATH}/${shareId}`;

    await shareUrl({url})
      .catch(() => void 0)
      .then(() => {
        analytics.trackEvent('share', {
          content_type: 'moodlight',
          item_id: moodlightId,
          item_name: moodlightName,
        });
      });
  };

  const createShareMoodLightId = (moodLight: CustomMoodLight) =>
    shareApi
      .createShareMoodLightId({
        moodLightCreateShareDto: {
          category: moodLight.category,
          id: moodLight.id,
          // @ts-expect-error mismatch between api and types
          modified: moodLight.modified,
          name: moodLight.name,
          colors: moodLight.colors,
          type: moodLight.type,
          tempo: moodLight.tempo,
          originalMoodLightId: moodLight.originalMoodLightId,
          led3Meta: moodLight.led3Meta,
        },
      })
      .then(r => ({...r, data: {...r.data, moodlightId: moodLight.id}}));

  const downloadMoodLight = React.useCallback(
    (moodLight: CustomMoodLight) =>
      saveMoodlight(moodLight, user).then(updatedMoodLight => {
        if (!updatedMoodLight) return;

        dispatch(upsertAccountMoodLight(updatedMoodLight));

        return createShareMoodLightId(updatedMoodLight);
      }),
    [user],
  );

  const [activeProfileId, setActiveProfileId, activeProfileIdHistory] =
    useStateWithHistory(
      !currentActiveId && lanternMoodLight?.id ? lanternMoodLight?.id : '',
      2,
    );

  const userName = user?.firstName ?? 'USER';
  const getUpdatedPeakMoodLights = useGetUpdatedPeakMoodLights();
  const usernameCache = useGetAndUpdateUsernameCache();
  const moodLightListData: {
    title: string;
    data: MoodLight[];
  }[] = React.useMemo(() => {
    const filteredMoodLights = peakMoodLights.filter(
      peakMoodLight =>
        !getAccountMoodLight(peakMoodLight.id) &&
        isCustomMoodLight(peakMoodLight),
    );
    return [
      ...(shouldShowPeakMoodLights &&
      filteredMoodLights &&
      filteredMoodLights.length > 0
        ? [{title: 'NEW PEAK MOOD LIGHTS', data: filteredMoodLights}]
        : []),
      {
        title: `${userName}'S ACCOUNT`,
        data: accountMoodLights,
      },
      {
        title: Strings.EXCLUSIVE,
        data: exclusiveMoodLights.filter(moodlight => {
          if (!moodlight.models) return true;

          if (!currentDevice?.modelNumberString) return false;

          return moodlight.models.includes(currentDevice?.modelNumberString);
        }),
      },
    ];
  }, [
    accountMoodLights,
    exclusiveMoodLights,
    getAccountMoodLight,
    peakMoodLights,
    shouldShowPeakMoodLights,
    userName,
  ]);

  const debouncedWriteLanternMoodLight = useDebouncedWriteLanternMoodLight();

  const generateDuplicateName = React.useCallback(
    (moodLight: CustomMoodLight) => {
      let currentIndex = 2;
      let newName = '';
      let foundName: CustomMoodLight | undefined = undefined;
      do {
        newName = moodLight.name + ` ${currentIndex}`;
        foundName = accountMoodLights.find(light => light.name === newName);
        currentIndex += 1;
      } while (foundName !== undefined);
      return newName;
    },
    [accountMoodLights],
  );

  const moodLightSelected = React.useCallback(
    (id: string) => {
      const lastActiveId =
        activeProfileIdHistory.position - 1 < 0
          ? undefined
          : activeProfileIdHistory.history[activeProfileIdHistory.position - 1];

      if (lastActiveId) {
        // Simulate lantern turn off
        analytics.trackEvent(
          'lantern off',
          {},
          undefined,
          ({timestamp, ...properties}) => ({
            ...properties,
            ...(timestamp && {
              duration: elapsedSecondsSince(timestamp),
            }),
          }),
        );
      }

      if (id === activeProfileId) {
        if (!currentActiveId) {
          peak?.stopLantern();
          dispatch(setLanternMoodLightId());
        }
        setActiveProfileId('');
        setActiveMoodLightId && setActiveMoodLightId('');
        if (currentDevice && !currentActiveId) {
          dispatch(
            setPeakMoodLights(
              getUpdatedPeakMoodLights({lanternMoodLight: undefined}),
            ),
          );
          dispatch(
            updateDeviceSettings({
              syncUserLanternPreference: true,
              moodLightId: undefined,
              ...currentDevice,
              settings: {
                ...currentDevice.settings,
                lanternMode: false,
              },
            }),
          );
        }
      } else {
        peak?.startLantern();
        setActiveProfileId(id);
        setActiveMoodLightId && setActiveMoodLightId(id);
        const moodLight = getMoodLight(id);

        if (moodLight) {
          analytics.trackEvent('lantern on', {
            mood_light_id: moodLight.id,
            mood_light_type: moodLight.category,
            timestamp: Date.now(),
          });

          debouncedWriteLanternMoodLight({
            ...moodLight,
            ...(isCustomMoodLight(moodLight) && {
              tempo: Number(moodLight.tempo),
              type: Number(moodLight.type),
            }),
          });
        }

        !currentActiveId && dispatch(setLanternMoodLightId(id));

        if (currentDevice && !currentActiveId) {
          dispatch(
            setPeakMoodLights(
              getUpdatedPeakMoodLights({lanternMoodLight: moodLight}),
            ),
          );
          dispatch(
            updateDeviceSettings({
              syncUserLanternPreference: true,
              moodLightId: moodLight?.id ?? undefined,
              ...currentDevice,
              settings: {
                ...currentDevice.settings,
                lanternMode: true,
                lanternColor: undefined,
                lanternPattern: undefined,
                partyMode: undefined,
              },
            }),
          );
        }
      }
    },
    [
      peak,
      activeProfileId,
      currentDevice,
      debouncedWriteLanternMoodLight,
      currentActiveId,
      getMoodLight,
      getUpdatedPeakMoodLights,
      setActiveMoodLightId,
    ],
  );

  const onLoginClick = React.useCallback(() => {
    navigate(Screens.Login, {
      redirect: (currentActiveId
        ? toHeatProfileEdit({profileId: currentActiveId})
        : toMoodLightLibrary
      ).encode(),
    });
  }, [currentActiveId, navigate]);

  const {isUserVerified} = useUserVerifiedGuard();

  const [
    {loading, value: createdShare, error: createdShareError},
    onShareButtonPress,
  ] = useAsyncFn(
    async (moodLight: CustomMoodLight, isPeakMoodLight: boolean) => {
      if (!user) {
        onRequireLogin(
          Alerts.ACCESS_DENIED,
          Alerts.FEATURE_ACCESS_MESSAGE,
          onLoginClick,
        );

        return;
      }

      if (!isUserVerified()) return;

      let matchingMoodLight;

      if (isPeakMoodLight) {
        const moodLightCopy = findMatchingMoodLight(
          moodLight,
          accountMoodLights,
        );
        if (moodLightCopy) {
          matchingMoodLight = moodLightCopy as CustomMoodLight;
        }
      }

      if (isPeakMoodLight && !matchingMoodLight) {
        return downloadMoodLight({...moodLight, id: ulid()});
      }

      return createShareMoodLightId(matchingMoodLight ?? moodLight);
    },
    [accountMoodLights, downloadMoodLight, isUserVerified, onLoginClick, user],
  );

  React.useEffect(() => {
    if (!createdShareError) return;

    Alert.alert(Alerts.MOOD_LIGHT_SHARE_ERROR_TITLE);
  }, [createdShareError]);

  React.useEffect(() => {
    if (!createdShare) return;

    const {
      shareMoodLightId: shareId,
      moodLightName: moodlightName,
      moodlightId,
    } = createdShare.data;

    const profileName = moodlightName || 'Mood Profile';

    Alert.alert(
      interpolate(Alerts.SHARE_PROFILE_READY_TITLE, {profileName}),
      interpolate(Alerts.SHARE_PROFILE_READY_BODY, {profileName}),
      [
        {
          style: 'cancel',
          text: Strings.CANCEL,
        },
        {
          onPress: () =>
            shareMoodLightById({shareId, moodlightId, moodlightName}).catch(
              () => void 0,
            ),
          text: Strings.SHARE,
        },
      ],
    );
  }, [createdShare]);

  const moreActionsSelected = React.useCallback(
    (moodLight: MoodLight) => {
      const subtitle = isExclusiveMoodLight(moodLight)
        ? Strings.EXCLUSIVE
        : `${MOOD_TYPE_NAME_RECORD[moodLight.type]} • ${Math.round(
            moodLight.tempo * 100,
          )}%`;
      const isPeakMoodLight =
        isCustomMoodLight(moodLight) && !getAccountMoodLight(moodLight.id);

      const buttonPropArr: BottomSheetButtonProps[] = [
        {
          title: BottomSheetButtonTitle.ASSIGN,
          iconName: 'add',
          size: 18,
          onPress: () =>
            // @ts-expect-error because of the list being part of 2 separate screens in 2 different stacks
            navigate(Screens.AssignToAHeatProfile, {
              isPeakMoodLight,
              moodLight,
            }),
        },
      ];

      if (isCustomMoodLight(moodLight)) {
        const isPeakMoodLight = !getAccountMoodLight(moodLight.id);
        buttonPropArr.push(
          {
            title: BottomSheetButtonTitle.SHARE,
            iconName: 'share',
            size: 28,
            onPress: () => onShareButtonPress(moodLight, isPeakMoodLight),
          },
          isPeakMoodLight
            ? {
                title: BottomSheetButtonTitle.DOWNLOAD,
                iconName: 'download',
                size: 18,
                onPress: () =>
                  user
                    ? verifyGuard(
                        user,
                        () => {
                          download({...moodLight, id: ulid()});
                        },
                        navigate,
                      )
                    : onRequireLogin(
                        Alerts.ACCESS_DENIED,
                        Alerts.FEATURE_ACCESS_MESSAGE,
                        onLoginClick,
                      ),
              }
            : {
                title: BottomSheetButtonTitle.DUPLICATE,
                iconName: 'duplicate',
                size: 23,
                onPress: () => {
                  // duplicate creates a new copy with a new originalMoodLightId which
                  // allows the user to edit this new mood light
                  const newUlid = ulid();
                  duplicate({
                    ...moodLight,
                    id: newUlid,
                    originalMoodLightId: newUlid,
                    name: generateDuplicateName(moodLight),
                  });
                },
              },
          {
            title: BottomSheetButtonTitle.DELETE,
            iconName: 'delete',
            size: 28,
            onPress: () =>
              deleteMoodLight({
                moodLight,
                isPeakMoodLight,
                shouldTurnOffLantern: !currentActiveId,
                onDelete: () => {
                  analytics.trackEvent('mood light delete', {});
                },
              }),
          },
        );
      }
      BottomSheetComponent.display(
        <BottomSheetDetail
          header={{
            icon: (
              <MultiGradientBackground
                gradientShadeFunction={iconGradientFunction}
                colors={moodLight.colors}
                style={{...centerStyle}}>
                <MoodLightIcon
                  style={{height: 24, width: 24, backgroundColor: undefined}}
                  iconStyle={{height: '100%', width: '100%'}}
                />
              </MultiGradientBackground>
            ),
            title: moodLight.name,
            subtitle,
          }}
          buttonPropArr={buttonPropArr}
        />,
      );
    },
    [
      deleteMoodLight,
      download,
      duplicate,
      generateDuplicateName,
      getAccountMoodLight,
      iconGradientFunction,
      currentActiveId,
      navigate,
      onShareButtonPress,
      onLoginClick,
      user,
    ],
  );

  useSpinner({
    isVisible: loading || isDeleting || isDownloading || isDuplicating,
    text: loading
      ? Strings.PREPARING_MOOD_LIGHT_SHARE
      : isDeleting
        ? Strings.DELETING
        : Strings.SAVING,
    color: appColors.white,
  });

  React.useEffect(() => {
    user && get();
  }, [get, user]);

  React.useEffect(() => {
    if (!isFocused && activeProfileId !== '') {
      setActiveMoodLightId && setActiveMoodLightId(activeProfileId);
    }
  }, [activeProfileId, isFocused, setActiveMoodLightId]);

  React.useEffect(() => {
    if (currentActiveId) {
      setActiveProfileId(currentActiveId);
    }
  }, [currentActiveId]);

  React.useEffect(() => {
    if (moodLights && !getError) {
      dispatch(upsertAccountMoodLights(moodLights));
    }
  }, [getError, moodLights]);

  const onMoodLightCardPress = (item: MoodLight) => {
    if (isExclusiveMoodLight(item)) {
      navigate(Screens.ExclusiveMood, {
        exclusive: item,
      });
    } else if (isCustomMoodLight(item)) {
      if (!hasLed3Api && isAdvancedMoodLight(item)) {
        onIncompatiblePeak();
      } else {
        navigate(Navigators.MainNavigator, {
          screen: Navigators.MoodLightController,
          params: {
            screen: Screens.MoodLightEdit,
            params: {
              id: item.id,
              isFromEditProfile: !!currentActiveId,
            },
          },
        });
      }
    }
  };

  return (
    <ScreenContainer>
      <ListContainer>
        <SectionList
          sections={moodLightListData}
          renderItem={({item}) => (
            <MoodLightCard
              moodLight={item}
              isActive={item.id === activeProfileId}
              setActiveProfile={moodLightSelected}
              theme={theme}
              onPress={() => onMoodLightCardPress(item)}
              moreActionsSelection={() => moreActionsSelected(item)}
              username={
                isCustomMoodLight(item)
                  ? usernameCache[item.originalMoodLightId]
                  : undefined
              }
            />
          )}
          keyExtractor={(item: MoodLight) => item.id.toString()}
          nestedScrollEnabled={true}
          renderSectionHeader={({section: {title}}) => (
            <SectionTitle
              title={title}
              style={{paddingHorizontal: 8}}
              titleStyle={{
                fontFamily: 'Roboto-Regular',
                fontWeight: '400',
                fontSize: 12,
                lineHeight: 16,
                marginRight: 11,
                color: appColors.white,
              }}
              fillerStyle={{backgroundColor: appColors.white20}}
            />
          )}
        />
      </ListContainer>
    </ScreenContainer>
  );
};

const onIncompatiblePeak = () => {
  Alert.alert(
    Alerts.INCOMPATIBLE_ANIMATION_TITLE,
    Alerts.INCOMPATIBLE_ANIMATION_BODY,
    [
      {
        text: Strings.OKAY,
        style: 'cancel',
      },
    ],
  );
};

const onSuccessDownload = (moodLight: CustomMoodLight) => {
  Alert.alert(
    Alerts.MOOD_DOWNLOADED,
    interpolate(Alerts.MOOD_DOWNLOADED_BODY, {
      name: moodLight.name,
    }),
  );

  analytics.trackEvent('mood light download', {mood_light_id: moodLight.id});
};

const onSuccessDuplicate = (moodLight: CustomMoodLight) =>
  Alert.alert(
    Alerts.MOOD_DUPLICATED,
    interpolate(Alerts.MOOD_DUPLICATED_BODY, {
      name: moodLight.name,
    }),
  );

const onRequireLogin = (title: string, message: string, onLogin: () => void) =>
  Alert.alert(
    title,
    message,
    [
      {
        text: Strings.LOG_IN,
        onPress: () => onLogin(),
        style: 'default',
      },
      {text: Strings.CANCEL, style: 'cancel'},
    ],
    {cancelable: false},
  );

const onSaveError = (error: Error) =>
  Alert.alert(Alerts.MOOD_LIGHT_CREATE_ERROR_TITLE, error.message);

const ScreenContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
});

const ListContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  flexBasis: 0,
});
