import {Middleware, configureStore} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  createMigrate,
  persistReducer,
  persistStore,
} from 'redux-persist';

import {PersistStorage} from '../../shims/PersistStorage';
import {appLog} from '../Logger';
import {rootReducer} from '../redux';
import {Store} from '../types';
import {migrationVersion, migrations} from './migrations';

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: migrationVersion,
    storage: PersistStorage,
    migrate: createMigrate(migrations, {debug: false}),
    blacklist: ['ble'] as (keyof Store)[],
  },
  rootReducer,
);

const spammyActions = new Set(['ble/updateDevice']);

const loggerMiddleware: Middleware<void, Store> = () => next => action => {
  if (spammyActions.has(action.type)) return next(action);

  appLog.info(`redux: ${action.type}`, {type: action.type});

  return next(action);
};

export const ProviderStore = configureStore({
  reducer: persistedReducer,
  // @ts-ignore
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([loggerMiddleware]),
});

export const Persistor = persistStore(ProviderStore);
