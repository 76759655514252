import React from 'react';
import {Image, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {logoExtended, logoExtendedBlack} from '../../../assets/images';
import {useStoryNavigation} from '../../../lib/navigation/story';
import {useSafeArea} from '../../../lib/useSafeArea';

export interface Props<T> {
  as?: React.ComponentType<T>;
  contentStyle?: StyleProp<ViewStyle>;
}

export const RehashScreen = <T extends Record<string, any>>({
  as: Component = View as unknown as React.ComponentType<T>,
  children,
  style,
  contentStyle,
  ...props
}: React.PropsWithChildren<Props<T>> & T) => {
  const {isDark} = useStoryNavigation();

  const {top} = useSafeArea();
  const height = 99 + top;

  return (
    <Component {...(props as T)} style={[styles.component, style]}>
      <Image
        style={[styles.logo, {top: top + 41}]}
        source={isDark ? logoExtendedBlack : logoExtended}
      />

      <View style={[styles.content, {marginTop: height}, contentStyle]}>
        {children}
      </View>
    </Component>
  );
};

const styles = StyleSheet.create({
  component: {
    display: 'flex',
    flex: 1,
  },
  logo: {
    position: 'absolute',
    left: 14,
    height: 44,
    resizeMode: 'contain',
  },
  content: {flex: 1},
});
