import {CommunityMoodlightRuntimeDto} from 'puffco-api-axios-client';
import React from 'react';
import {Image, StyleSheet, View} from 'react-native';

import {communityMoodlightRuntime} from '../../../assets/images';
import {SwitchableBackground} from '../../../components';
import {appColors} from '../../../constants';
import {Screens} from '../../../constants';
import {formatNumberWithCommas} from '../../../lib/formatNumber';
import {RehashStoryScreenProps} from '../../../navigation/navigators/RehashStoryNavigator';
import {BoldText, MediumText, RegularText} from '../components';
import {RehashScreen} from '../components/RehashScreen';

export interface Props extends CommunityMoodlightRuntimeDto {}

interface ScreenProps
  extends RehashStoryScreenProps<
    typeof Screens.RehashCommunityMoodlightRuntime
  > {}

const theme = {
  background: {color: '#FFBCCA'},
};

export const CommunityMoodlightRuntime: React.FC<ScreenProps> = ({route}) => {
  const {title, duration, unit} = route.params;

  return (
    <RehashScreen
      as={SwitchableBackground}
      style={styles.container}
      background={theme.background}>
      <MediumText size={24} style={styles.title}>
        {title}
      </MediumText>

      <Image source={communityMoodlightRuntime} style={styles.image} />

      <View style={styles.counterContainer}>
        {duration && (
          <BoldText size={80} style={styles.counter}>
            {formatNumberWithCommas(duration)}
          </BoldText>
        )}

        <RegularText style={styles.unit}>{unit}</RegularText>
      </View>
    </RehashScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '10%',
  },
  title: {color: appColors.black, textAlign: 'center'},
  image: {
    flex: 1,
    width: '120%',
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  counter: {color: appColors.black},
  unit: {fontSize: 20, marginBottom: 14, marginLeft: 4, color: appColors.black},
});
