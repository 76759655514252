import {FavoriteDabTimeDto} from 'puffco-api-axios-client';
import React from 'react';
import {Dimensions, Image, View} from 'react-native';

import {stripBg} from '../../../assets/images';
import {
  ResponsiveText,
  RowContainer,
  SwitchableBackground,
} from '../../../components';
import {appColors} from '../../../constants';
import {Screens} from '../../../constants';
import styled from '../../../lib/styled';
import {RehashStoryScreenProps} from '../../../navigation/navigators/RehashStoryNavigator';
import {RehashScreen} from '../components/RehashScreen';
import {dabTimeThemes} from '../themes';

export interface Props extends FavoriteDabTimeDto {}

interface ScreenProps
  extends RehashStoryScreenProps<typeof Screens.RehashFavoriteDabTime> {}

const {width} = Dimensions.get('window');

export const FavoriteDabTime: React.FC<ScreenProps> = ({route}) => {
  const {title, slug, subtitle, percentage} = route.params;

  const theme = dabTimeThemes[slug];

  return (
    <RehashScreen as={Container} background={{gradient: theme.colors}}>
      <Title xs={20} md={24}>
        {title}
      </Title>

      <Slug xs={64} sm={88} md={104}>
        {slug}
      </Slug>

      <SubtitleContainer>
        <PercentageContainer>
          <StripBackground source={stripBg} />

          <PercentageText xs={16} md={18}>{`${percentage}%`}</PercentageText>
        </PercentageContainer>

        <Subtitle xs={16} md={18}>
          {subtitle}
        </Subtitle>
      </SubtitleContainer>

      <EllipseImageContainer>
        <EllipseImage source={theme.image} />
      </EllipseImageContainer>
    </RehashScreen>
  );
};

const Container = styled(SwitchableBackground)({
  flex: 1,
});

const BaseText = styled(ResponsiveText)({
  color: appColors.white,
  fontFamily: 'Roboto-Bold',
});

const Title = styled(BaseText)({
  fontFamily: 'Roboto-Medium',
  textAlign: 'center',
});

const Slug = styled(BaseText)({
  minHeight: 150,
  transform: [{translateX: -10}],
});

const PercentageText = styled(BaseText)({
  textAlign: 'center',
  position: 'absolute',
});

const SubtitleContainer = styled(RowContainer)({
  alignItems: 'flex-start',
  marginTop: 8,
  marginRight: '10%',
  maxWidth: '55%',
  alignSelf: 'flex-end',
});

const PercentageContainer = styled(View)({
  position: 'relative',
  alignItems: 'center',
  marginTop: 14,
  justifyContent: 'center',
  width: 36,
});

const StripBackground = styled(Image)({
  flex: 1,
  resizeMode: 'contain',
  position: 'absolute',
});

const Subtitle = styled(ResponsiveText)({
  color: appColors.white,
  lineHeight: 25,
});

const EllipseImageContainer = styled(View)({
  width: '100%',
  marginTop: '2%',
  flex: 1,
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
});

const EllipseImage = styled(Image)({
  width: '100%',
  // 0.9 is the approximate aspect ratio of the image. this mimics background-position: top
  height: width / 0.9,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
});
