import {useNavigation} from '@react-navigation/native';
import React from 'react';

import {BottomSheetComponent} from '../components';
import {Alerts, Strings} from '../constants/Strings';
import {Connection} from '../contexts/connection';
import {
  RootStackParamList,
  RootStackScreenProps,
} from '../navigation/navigators/RootStackNavigator';
import {toHome} from '../navigation/navigators/util';
import {Alert} from '../shims/alert';

let hasAlert = false;

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export function useDisconnectGuard() {
  const navigation = useNavigation<Navigation>();

  const {peak} = Connection.useContainer();
  const connected = !!peak;

  React.useEffect(() => {
    if (connected || hasAlert) return;

    hasAlert = true;

    Alert.alert(Alerts.DISCONNECTED_TITLE, Alerts.CONNECT_TO_DEVICE, [
      {
        text: Strings.OKAY,
        onPress: () => {
          hasAlert = false;
        },
      },
    ]);

    BottomSheetComponent.close();
    navigation.navigate(...toHome);
  }, [connected, navigation]);
}
