import {
  DefaultTheme,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import React from 'react';
import {
  ActivityIndicator,
  AppState,
  AppStateStatus,
  Platform,
  View,
} from 'react-native';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Provider, useSelector} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {SWRConfig, SWRConfiguration} from 'swr';

import {AppStateSensor} from './AppStateSensor';
import borealis from './assets/b64_images/borealis_base64.json';
import {
  BottomSheetProvider,
  ModalProvider,
  SpinnerProvider,
} from './components';
import {AppStatusBar} from './components/StatusBar';
import {ScreenNames, fillStyle, isNavigator} from './constants';
import {Auth} from './contexts/auth';
import {Connection} from './contexts/connection';
import {Context} from './contexts/context';
import {Interstitial} from './contexts/interstitial';
import {appLog} from './lib/Logger';
import NavigationService, {linking} from './lib/NavigationService';
import {copyImgToCacheAsync} from './lib/copyImgToCacheAsync';
import {usePushNotification} from './lib/hooks';
import {useAnalyticsDataCollection} from './lib/hooks/useAnalyticsDataCollection';
import {useDeeplinking} from './lib/hooks/useDeeplinking';
import {currentDeviceIdSelector} from './lib/redux/bleSlice';
import {Persistor, ProviderStore} from './lib/redux/persistor';
import styled from './lib/styled';
import {
  RootStackNavigator,
  RootStackParamList,
} from './navigation/navigators/RootStackNavigator';
import {
  optionsToRoute,
  toHome,
  toStartPairing,
} from './navigation/navigators/util';
import {GoogleAuthProvider} from './shims/GoogleAuthProvider';
import {ErrorBoundary, registerAppContainer} from './shims/sentry';
import {analytics} from './src/services/analytics';

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

const AppWithSwr: React.FC<React.PropsWithChildren> = ({children}) => {
  const value = React.useMemo(
    (): SWRConfiguration => ({
      provider: () => new Map(),
      isOnline() {
        /* Customize the network state detector */
        return true;
      },
      isVisible() {
        /* Customize the visibility state detector */
        return true;
      },
      initFocus(callback) {
        let appState = AppState.currentState;

        const onAppStateChange = (nextAppState: AppStateStatus) => {
          /* If it's resuming from background or inactive mode to active one */
          if (
            ['inactive', 'background'].includes(appState) &&
            nextAppState === 'active'
          ) {
            callback();
          }

          appState = nextAppState;
        };

        // Subscribe to the app state change events
        const subscription = AppState.addEventListener(
          'change',
          onAppStateChange,
        );

        return () => {
          subscription.remove();
        };
      },
      initReconnect() {
        /* Register the listener with your state provider */
      },
    }),
    [],
  );

  return <SWRConfig {...{value}}>{children}</SWRConfig>;
};

const AppWithAuth: React.FC<React.PropsWithChildren> = ({children}) => {
  const hasDevice = !!useSelector(currentDeviceIdSelector);

  return (
    <GoogleAuthProvider>
      <Auth.Provider
        initialState={{
          onLogout: () => {
            const redirect = hasDevice ? toHome : toStartPairing();

            const route = optionsToRoute(redirect);

            const instance = NavigationService.instance();

            // TODO: Why do we need to call resetRoot multiple times to take effect?
            // E.g. on referral screen, we have to reset twice to navigate back to `redirect`
            const reset = (index: number) => {
              const schedule = (): void => {
                instance?.removeListener('state', schedule);

                if (index < 0) return;

                reset(index - 1);
              };

              instance?.addListener('state', schedule);
              instance?.resetRoot({index, routes: [route]});
            };

            reset(instance?.getState().index ?? 0);
          },
        }}>
        <Context.Provider>{children}</Context.Provider>
      </Auth.Provider>
    </GoogleAuthProvider>
  );
};

const InnerApp: React.FC = () => {
  usePushNotification();
  useDeeplinking();
  useAnalyticsDataCollection();

  return <AppStateSensor />;
};

export const App: React.FC = () => {
  const navigationRef = useNavigationContainerRef<RootStackParamList>();
  const [activeRouteName, setActiveRouteName] = React.useState<ScreenNames>();

  React.useEffect(() => {
    const load = async () => {
      if (Platform.OS === 'android') {
        await copyImgToCacheAsync(borealis, 'borealis');
      }
    };
    load();
  }, []);

  React.useEffect(() => {
    NavigationService.setTopLevelNavigator(navigationRef);

    registerAppContainer(navigationRef);
  }, [navigationRef]);

  return (
    <ErrorBoundary>
      <AppWithSwr>
        <GestureHandlerRootView style={{flex: 1}}>
          <SafeAreaProvider>
            <Provider store={ProviderStore}>
              <PersistGate
                loading={<ActivityIndicator />}
                persistor={Persistor}>
                <AppWithAuth>
                  <Connection.Provider>
                    <CenteredContainer>
                      <FillView>
                        <AppStatusBar {...{activeRouteName}} />

                        <NavigationContainer
                          ref={navigationRef}
                          {...{theme, linking}}
                          onReady={() => {
                            NavigationService.setTopLevelNavigator(
                              navigationRef,
                            );

                            setActiveRouteName(
                              navigationRef.current?.getCurrentRoute()?.name as
                                | ScreenNames
                                | undefined,
                            );
                          }}
                          onStateChange={() => {
                            const previous = activeRouteName;

                            const current =
                              navigationRef.current?.getCurrentRoute()?.name as
                                | ScreenNames
                                | undefined;

                            setActiveRouteName(current);

                            if (
                              current &&
                              previous !== current &&
                              !isNavigator(current)
                            ) {
                              analytics.trackEvent('screen view', {
                                screen_name: current,
                              });
                            }
                          }}
                          onUnhandledAction={action => {
                            appLog.error('Unhandled navigation action.', {
                              action,
                            });
                          }}>
                          <ModalProvider>
                            <Interstitial.Provider>
                              <RootStackNavigator />
                            </Interstitial.Provider>
                          </ModalProvider>
                        </NavigationContainer>

                        <BottomSheetProvider />
                      </FillView>

                      <InnerApp />

                      <SpinnerProvider />
                    </CenteredContainer>
                  </Connection.Provider>
                </AppWithAuth>
              </PersistGate>
            </Provider>
          </SafeAreaProvider>
        </GestureHandlerRootView>
      </AppWithSwr>
    </ErrorBoundary>
  );
};

const FillView = styled(View)(fillStyle);

const CenteredContainer = styled(View)({
  ...fillStyle,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
